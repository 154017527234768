import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { useMutation, useQuery } from '@apollo/client'
import communityConnectUsersMutation from 'GraphQL/Mutations/Community/communityConnectUsers.graphql'
import createNoteMutation from 'GraphQL/Mutations/User/createNote.graphql'
import updateNoteMutation from 'GraphQL/Mutations/User/updateNote.graphql'
import communityUserQuery from 'GraphQL/Queries/Community/communityUser.graphql'
import getCommunityUserQuery from 'GraphQL/Queries/CommunityUser/getCommunityUser.graphql'
import noteQuery from 'GraphQL/Queries/Notes/note.graphql'
import notesQuery from 'GraphQL/Queries/Notes/notes.graphql'
import { updateCommunityUserDirectConnectionsUpdater } from 'GraphQL/Updaters/User'
import { forEachPromise } from 'Utils/Promises'

import { DebouncedFunc } from 'lodash'
import debounce from 'lodash/debounce'
import keyBy from 'lodash/keyBy'

import {
  MentionTextareaAddHandler,
  MentionTextareaChangeHandler,
} from 'Components/Blocks/MentionTextarea'

import { ACTION_KIND } from 'Constants/graph'
import {
  ConnectionKind,
  NoteTargetEntityKind,
  Source,
} from 'Constants/mainGraphQL'

import { useCommunity } from 'Hooks'

import EventBus from 'Services/EventBus'

import useAbility from '../../useAbility'
import { IMentions, parseNoteTags, parseToMentionsInput } from '../../utils'

export enum NoteFormField {
  CommunityId = 'communityId',
  UserId = 'userId',
  Content = 'content',
  IsPublic = 'isPublic',
  IsFavorite = 'isFavorite',
  ExistingMentions = 'existingMentions',
  MentionedItems = 'mentionedItems',
}

export interface INoteFormValues {
  [NoteFormField.CommunityId]?: string
  [NoteFormField.UserId]?: string
  [NoteFormField.Content]: string
  [NoteFormField.IsPublic]: boolean
  [NoteFormField.IsFavorite]: boolean
  [NoteFormField.ExistingMentions]?: Record<string, MainSchema.Mention>
  [NoteFormField.MentionedItems]: IMentions
}

export interface IUseNoteFormProps {
  userId: string
  noteId?: string
}

export enum NoteFormStatus {
  Idle = 'idle',
  Loading = 'loading',
  Success = 'submitted',
  Error = 'error',
}

export interface INoteFormState {
  status: NoteFormStatus
  isDirty: boolean
}

// TODO: replace custom form solution with react hook form
const useNoteForm = (props: IUseNoteFormProps) => {
  const { community } = useCommunity()
  const [noteId, setNoteId] = useState(props.noteId)
  const [isLoading, setIsLoading] = useState(true)

  // queries
  const { data: userData } = useQuery<
    Pick<MainSchema.Query, 'communityUser'>,
    MainSchema.QueryGetCommunityUserArgs
  >(communityUserQuery, {
    fetchPolicy: 'network-only',
    skip: !community?.id || !props.userId,
    variables:
      community?.id && props.userId
        ? { communityId: community.id, userId: props.userId }
        : undefined,
  })
  const { data: noteData } = useQuery<
    Pick<MainSchema.Query, 'note'>,
    MainSchema.QueryNoteArgs
  >(noteQuery, {
    skip: !community?.id || !noteId,
    variables:
      community?.id && noteId
        ? { communityId: community.id, noteId }
        : undefined,
  })

  const user = userData?.communityUser
  const note = noteData?.note

  // mutations
  const [createNote] = useMutation<
    Pick<MainSchema.Mutation, 'createNote'>,
    MainSchema.MutationCreateNoteArgs
  >(createNoteMutation)
  const [updateNote] = useMutation<
    Pick<MainSchema.Mutation, 'updateNote'>,
    MainSchema.MutationUpdateNoteArgs
  >(updateNoteMutation)
  const [communityConnectUsers] = useMutation<
    Pick<MainSchema.Mutation, 'communityConnectUsers'>,
    MainSchema.MutationCommunityConnectUsersArgs
  >(communityConnectUsersMutation)

  // form
  const debouncedSave = useRef<DebouncedFunc<
    (formValues: INoteFormValues) => void
  > | null>(null)
  const initialValues: INoteFormValues = useMemo(
    () => ({
      communityId: undefined,
      userId: undefined,
      content: '',
      isPublic: false,
      isFavorite: false,
      existingMentions: undefined,
      mentionedItems: {
        projects: [],
        events: [],
        roles: [],
        customTags: [],
        groups: [],
        toAppendUserIds: [],
        toMentionedUserIds: [],
        toMeetUserIds: [],
        toMeetUsers: [],
        toAddUsers: [],
        toAppendSkills: [],
        toMentionedSkills: [],
        toAppendNeedSkills: [],
        toMentionedNeedSkills: [],
      },
    }),
    [],
  )
  const [formValues, setFormValues] = useState<INoteFormValues>(initialValues)
  const [isDirty, setIsDirty] = useState(false)
  const [formStatus, setFormStatus] = useState<NoteFormStatus>(
    NoteFormStatus.Idle,
  )
  const isFormValid = !!formValues.content
  const isEdit = !!note

  // abilities
  const {
    canCreate,
    canCreatePublic,
    canCreatePrivate,
    canEditToPrivate,
    canEditToPublic,
  } = useAbility()

  const canSwitchToPublic = isEdit
    ? canEditToPublic(note)
    : canCreatePublic(props.userId)
  const canSwitchToPrivate = isEdit
    ? canEditToPrivate(note)
    : canCreatePrivate(props.userId)
  const canToggleIsPublic =
    (formValues.isPublic && canSwitchToPrivate) ||
    (!formValues.isPublic && canSwitchToPublic)
  const canSubmit = isFormValid && (isEdit ? true : canCreate(props.userId))

  const onChange = useCallback(
    <T extends NoteFormField>(
      field: T,
      value: INoteFormValues[T],
      shouldDirtyIfChanged: boolean = true,
    ) => {
      setFormValues(currentFormValues => ({
        ...currentFormValues,
        [field]: value,
      }))
      setIsDirty(
        currentIsDirty =>
          currentIsDirty ||
          (shouldDirtyIfChanged && formValues[field] !== value),
      )
    },
    [formValues],
  )

  const handleToggleIsPublic = useCallback<
    React.ChangeEventHandler<HTMLInputElement>
  >(
    event => {
      const { checked } = event.target

      if ((checked && canSwitchToPublic) || (!checked && canSwitchToPrivate)) {
        onChange(NoteFormField.IsPublic, checked)
      }
    },
    [canSwitchToPublic, canSwitchToPrivate, onChange],
  )

  const handleToggleIsFavorite = useCallback(() => {
    onChange(NoteFormField.IsFavorite, !formValues[NoteFormField.IsFavorite])
  }, [onChange, formValues])

  const handleAddMention = useCallback<MentionTextareaAddHandler>(
    option => {
      switch (option.kind) {
        case ACTION_KIND.user:
          EventBus.trigger(EventBus.actions.graph.addUserById, {
            userId: option.id,
            fromUserId: props.userId,
          })
          break
        case ACTION_KIND.custom:
        case ACTION_KIND.event:
        case ACTION_KIND.group:
        case ACTION_KIND.project:
        case ACTION_KIND.role:
        case ACTION_KIND.skill:
          EventBus.trigger(EventBus.actions.graph.addSkillTags, {
            id: option.id,
            name: option.label,
            kind: option.kind,
            userId: props.userId,
          })
          break
        case ACTION_KIND.organization:
          // TODO: Add mentioning organizations to notes
          break
        default:
          break
      }
    },
    [props.userId],
  )

  const handleChangeContent = useCallback<MentionTextareaChangeHandler>(
    content => {
      onChange(NoteFormField.Content, content)
      onChange(
        NoteFormField.MentionedItems,
        parseNoteTags({
          content,
          targetUserSkills: user?.skills || [],
          targetUserNeedSkills: user?.needSkills,
        }),
        false,
      )
    },
    [onChange, user],
  )

  const handleUpdateNote = useCallback(
    async (noteId: string, formValues: INoteFormValues) => {
      if (!formValues[NoteFormField.CommunityId]) {
        return
      }

      await updateNote({
        variables: {
          noteId,
          communityId: formValues[NoteFormField.CommunityId],
          content: formValues[NoteFormField.Content],
          public: formValues[NoteFormField.IsPublic],
          favorite: formValues[NoteFormField.IsFavorite],
          mentions: parseToMentionsInput(
            formValues[NoteFormField.MentionedItems],
          ),
        },
        update: (store, data) => {
          store.writeQuery({
            query: noteQuery,
            variables: {
              noteId: data.data?.updateNote.id,
              communityId: formValues.communityId,
            },
            data: data?.data,
          })
        },
        refetchQueries: [
          notesQuery,
          {
            query: getCommunityUserQuery,
            variables: {
              communityId: formValues.communityId,
              userId: formValues.userId,
            },
            fetchPolicy: 'network-only',
          },
        ],
      })
    },
    [updateNote],
  )

  const handleCreateNote = useCallback(
    async (formValues: INoteFormValues) => {
      if (
        !formValues[NoteFormField.CommunityId] ||
        !formValues[NoteFormField.UserId]
      ) {
        return
      }

      const { data: createNoteData } = await createNote({
        variables: {
          communityId: formValues[NoteFormField.CommunityId],
          targetEntityId: formValues[NoteFormField.UserId],
          targetEntityKind: NoteTargetEntityKind.User,
          content: formValues[NoteFormField.Content],
          isPublic: formValues[NoteFormField.IsPublic],
          isFavorite: formValues[NoteFormField.IsFavorite],
          mentions: parseToMentionsInput(
            formValues[NoteFormField.MentionedItems],
          ),
        },
        update: (store, data) => {
          store.writeQuery({
            query: noteQuery,
            variables: {
              noteId: data.data?.createNote.id,
              communityId: formValues.communityId,
            },
            data: data?.data,
          })
        },
        refetchQueries: [
          notesQuery,
          {
            query: getCommunityUserQuery,
            variables: {
              communityId: formValues.communityId,
              userId: formValues.userId,
            },
            fetchPolicy: 'network-only',
          },
        ],
      })

      setNoteId(createNoteData?.createNote.id)
    },
    [createNote],
  )

  const handleSave = useCallback(
    async (formValues: INoteFormValues) => {
      setFormStatus(NoteFormStatus.Loading)
      setIsDirty(false)

      try {
        await forEachPromise(
          [
            ...formValues.mentionedItems.toAppendUserIds,
            ...formValues.mentionedItems.toMentionedUserIds,
          ],
          async appendUserId => {
            if (!formValues.communityId || !formValues.userId) {
              return
            }

            await communityConnectUsers({
              variables: {
                communityId: formValues.communityId,
                fromUserId: formValues.userId,
                toUserId: appendUserId,
                kind: ConnectionKind.Profile,
                source: Source.NetworkOs,
              },
              update: (store, { data: communityUserDirectConnectionsData }) => {
                updateCommunityUserDirectConnectionsUpdater(
                  formValues.communityId!,
                  formValues.userId!,
                  store,
                  communityUserDirectConnectionsData?.communityConnectUsers
                    .users[1],
                )
              },
            })
          },
        )

        if (noteId) {
          await handleUpdateNote(noteId, formValues)
        } else {
          await handleCreateNote(formValues)
        }

        setFormStatus(NoteFormStatus.Success)
      } catch {
        setFormStatus(NoteFormStatus.Error)
      }
    },
    [noteId, communityConnectUsers, handleUpdateNote, handleCreateNote],
  )

  // reset the form when community id or user id change
  useEffect(() => {
    setIsLoading(true)
    setNoteId(props.noteId)
    setFormValues({
      ...initialValues,
      communityId: community?.id,
      userId: props.userId,
    })
    setIsDirty(false)
    setFormStatus(NoteFormStatus.Idle)

    // ensure the user is on the graph
    EventBus.trigger(EventBus.actions.graph.addUserById, {
      userId: props.userId,
      isSelected: true,
    })
  }, [initialValues, community?.id, props.userId, props.noteId])

  // update form when note query loads
  useEffect(() => {
    if (!user || !noteData) {
      return
    }

    const userIds: string[] = []
    noteData.note.mentions?.forEach(mention => {
      switch (mention.kind) {
        case ACTION_KIND.user:
          userIds.push(mention.id)
          break
        case ACTION_KIND.custom:
        case ACTION_KIND.event:
        case ACTION_KIND.group:
        case ACTION_KIND.project:
        case ACTION_KIND.role:
        case ACTION_KIND.skill:
          EventBus.trigger(EventBus.actions.graph.addSkillTags, {
            id: mention.id,
            name: mention.name,
            kind: mention.kind,
            userId: props.userId,
          })
          break
        case ACTION_KIND.organization:
          // TODO: Add mentioning organizations to notes
          break
        default:
          break
      }
    })
    EventBus.trigger(EventBus.actions.graph.addUsersById, {
      userIds,
      forceLayoutReset: false,
    })

    setFormValues(currentValues => ({
      ...currentValues,
      content: noteData.note.content,
      mentionedItems: parseNoteTags({
        content: noteData.note.content,
        targetUserSkills: user?.skills || [],
        targetUserNeedSkills: user?.needSkills,
      }),
      isPublic: noteData.note.public,
      isFavorite: noteData.note.favorite,
      existingMentions: keyBy(noteData.note.mentions, 'id'),
    }))
  }, [user, noteData, handleAddMention, props.userId])

  // update loading state
  useEffect(() => {
    // user not loaded
    if (!user) {
      return
    }

    // note id provided, but note not loaded
    if (noteId && !note) {
      return
    }

    setIsLoading(false)
  }, [user, noteId, note])

  // keep the debounce save reference updated
  useEffect(() => {
    debouncedSave.current?.cancel()

    debouncedSave.current = debounce(handleSave, 500)
  }, [handleSave])

  // submit the debounced save
  useEffect(() => {
    if (isLoading || !isDirty || !isFormValid) {
      return
    }

    setFormStatus(NoteFormStatus.Idle)

    debouncedSave.current?.cancel()
    debouncedSave.current?.(formValues)
  }, [isLoading, isDirty, isFormValid, formValues])

  return {
    note,
    user,
    isLoading,
    formStatus,
    formValues,
    canToggleIsPublic,
    canSubmit,
    handleToggleIsFavorite,
    handleToggleIsPublic,
    handleAddMention,
    handleChangeContent,
  }
}

export default useNoteForm
