import { MdOutlineArrowForwardIos } from 'react-icons/md'

import styled, { css } from 'styled-components'
import { margin, MarginProps, position, PositionProps } from 'styled-system'
import { themeGet } from '@styled-system/theme-get'

import * as icons from 'Assets/Svg/icons'

export type IconType = keyof typeof icons

export const StyledIcon = styled.div`
  width: 12px;
  height: 12px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 100%;
    height: 100%;
    color: inherit;
  }
`

interface MenuHoveredCssProps {
  hovered?: boolean
}

const menuHoveredCss = ({ hovered }: MenuHoveredCssProps) =>
  hovered &&
  css`
    border: none;
    top: 0;
    right: 0;
    transform: translateX(100%);
    color: ${themeGet('colors.text.header')} !important;
  `

interface MenuScrollableCssProps {
  scrollable?: boolean
}

const menuScrollableCss = ({ scrollable }: MenuScrollableCssProps) =>
  scrollable &&
  css`
    max-height: 194px;
    overflow-y: auto;
  `

export interface IMenu
  extends PositionProps,
    MarginProps,
    MenuHoveredCssProps,
    MenuScrollableCssProps {}

export const Menu = styled.div<IMenu>`
  position: absolute;
  box-sizing: content-box;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  z-index: ${themeGet('zIndices.popover')};
  background-color: ${themeGet('colors.bg.primary')};
  border-radius: 4px;
  border: 1px solid #f0f0f0;
  box-shadow:
    0px 0px 0px 1px rgba(0, 0, 0, 0.05),
    0px 10px 15px -3px rgba(0, 0, 0, 0.1),
    0px 4px 6px -2px rgba(0, 0, 0, 0.05);

  > div {
    color: ${themeGet('colors.text.header')};

    &:hover {
      background-color: ${themeGet('colors.bg.hover')};
      color: ${themeGet('colors.main')};
    }
  }

  ${position}
  ${margin}

  ${menuHoveredCss}
  ${menuScrollableCss}
`

interface DividerCssProps {
  showDivider?: boolean
}
export const dividerCssProps = ({ showDivider }: DividerCssProps) =>
  showDivider &&
  css`
    border-bottom: 1px solid ${themeGet('colors.border.primary')};
  `
export const Item = styled.div<DividerCssProps>`
  position: relative;
  display: grid;
  align-items: center;
  grid-template-columns: auto 1fr auto;
  gap: ${themeGet('space.2')}px;
  padding: ${themeGet('space.2')}px ${themeGet('space.2')}px;
  font-weight: ${themeGet('fontWeight.0')};
  font-size: ${themeGet('fontSize.2')}px;
  cursor: pointer;
  white-space: nowrap;

  ${dividerCssProps}
`

export const ItemLabel = styled.div`
  display: flex;
  justify-content: left;
  width: calc(100%);
`

export const MenuUnfold = styled(MdOutlineArrowForwardIos)`
  display: flex;
  justify-content: right;
  margin-top: 2px;
`
