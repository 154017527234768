import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'

import { ApolloClient, useSubscription } from '@apollo/client'
import { IGraphPeopleNode } from 'Features/GraphNodes/NodeTypes'
import notificationPostedSubscription from 'GraphQL/Subscriptions/notificationPosted.graphql'
import { unreadNotificationsIncreaseCountUpdater } from 'GraphQL/Updaters/UnreadNotificationsCount'

import { InviteUserToCommunityModal } from 'Components/Blocks/Admin/Modals'
import { InvitesTable } from 'Components/Blocks/Admin/Tables'
import Header from 'Components/Blocks/Community/Header'
import GraphProvider from 'Components/Blocks/Graph/GraphProvider'
import MobileHeader from 'Components/Blocks/Mobile/Community/Header'
import MobileFooter from 'Components/Blocks/Mobile/Footer'
import CreateCommunityUserModal from 'Components/Blocks/Modals/CreateCommunityUser'
import ProfileModal from 'Components/Blocks/Modals/Profile'
import { NotificationsTable } from 'Components/Blocks/Tables'
import { Column, Loader, Row } from 'Components/UI'

import {
  useAppContext,
  useCommunity,
  useEntityModal,
  useOnClickOutside,
  useResponsiveLayout,
} from 'Hooks'

import { QUICK_ACTIONS } from 'Pages/Community/Dashboard/constants'
import { IOptions } from 'Pages/Community/Dashboard/Context'

import { COMMUNITY_PANEL, ONBOARDING_WELCOME } from 'Router/routes'

import CommunityContext from 'Services/CommunityContext'
import PermissionProvider from 'Services/Permission/Provider'

import NotMember from './NotMember'
import PrivacyAgreement from './PrivacyAgreement'
import {
  Container,
  Content,
  InviteTable,
  MobileContainer,
  NotificationsContainer,
  PageContent,
  PrivacyContainer,
} from './styles'

const graphControls = {
  reset: true,
  options: false,
  myNetwork: true,
  graphSnapshots: true,
  search: true,
  browse: true,
}

function Community() {
  const notificationsRef = useRef<HTMLDivElement | null>(null)
  const notificationsButtonRef = useRef<HTMLButtonElement | null>(null)
  const invitationsRef = useRef<HTMLDivElement | null>(null)
  const invitationsButtonRef = useRef<HTMLButtonElement | null>(null)

  const navigate = useNavigate()
  const { isMobile } = useResponsiveLayout()
  const { isRestricted, me, canShowDashboard } = useAppContext()
  const { community, loading: communityLoading, error } = useCommunity()
  const [inviteModal, inviteActions] = useEntityModal()
  const [createModal, createModalActions] = useEntityModal()

  const [noCommunity, setNoCommunity] = useState(false)
  const [notMember, setNotMember] = useState(false)
  const [tableInviteOpened, setTableInviteOpened] = useState(false)
  const [notificationsOpened, setNotificationsOpened] = useState(false)
  const [options, setOptions] = useState<IOptions | undefined>()

  useSubscription<Pick<MainSchema.Subscription, 'notificationPosted'>>(
    notificationPostedSubscription,
    {
      shouldResubscribe: true,
      onData: ({ client }) => {
        unreadNotificationsIncreaseCountUpdater(
          client as ApolloClient<
            Pick<MainSchema.Subscription, 'notificationPosted'>
          >,
          community?.id,
        )
      },
    },
  )

  useEffect(() => {
    if (error?.message === 'community.notMember') {
      setNotMember(true)
      navigate(COMMUNITY_PANEL)
    }
  }, [error, navigate])

  useEffect(() => {
    if (!communityLoading && !community && !noCommunity) {
      setNoCommunity(true)
    }
  }, [noCommunity, communityLoading, community])

  useEffect(() => {
    if (!canShowDashboard && isRestricted) {
      navigate(COMMUNITY_PANEL)
    }
  }, [isRestricted, navigate, canShowDashboard])

  const handleOpenInviteModal = inviteActions.openModal
  const handleCloseInviteModal = inviteActions.closeModal
  const handleOpenCreateModal = createModalActions.openModal
  const handleCloseCreateModal = createModalActions.closeModal

  const handleOpenInvitePanel = () => {
    setTableInviteOpened(opened => !opened)
    if (notificationsOpened) setNotificationsOpened(false)
  }
  const handleToggleNotifications = () => {
    setNotificationsOpened(opened => !opened)
    if (tableInviteOpened) setTableInviteOpened(false)
  }

  useOnClickOutside(
    notificationsRef,
    () => {
      if (notificationsOpened) setNotificationsOpened(false)
    },
    notificationsButtonRef,
  )

  useOnClickOutside(
    invitationsRef,
    () => {
      if (tableInviteOpened) setTableInviteOpened(false)
    },
    invitationsButtonRef,
  )

  const memoizedContext = useMemo(
    () => ({ community, noCommunity, communityLoading }),
    [community, communityLoading, noCommunity],
  )

  if (communityLoading) {
    return <Loader absolute />
  }

  if (!me?.onboardingCompletedAt && !me?.personalCommunityId) {
    navigate(ONBOARDING_WELCOME)
  }

  // TODO: Move to separate page and use redirect. Also isRestricted must be updated
  if (!me?.agreedToTermsAndConditions) {
    return (
      <CommunityContext.Provider value={memoizedContext}>
        <PermissionProvider>
          {isMobile ? (
            <MobileContainer>
              <MobileHeader menuHidden restricted />
              <PrivacyAgreement />
            </MobileContainer>
          ) : (
            <Container>
              <Header
                menuHidden
                restricted
                onOpenInvitePanel={handleOpenInvitePanel}
              />
              <Row fullHeight fullWidth justifyCenter p={5}>
                <PrivacyContainer>
                  <PrivacyAgreement />
                </PrivacyContainer>
              </Row>
            </Container>
          )}
        </PermissionProvider>
      </CommunityContext.Provider>
    )
  }

  if (notMember) {
    return (
      <CommunityContext.Provider value={memoizedContext}>
        <PermissionProvider>
          {isMobile ? (
            <MobileContainer>
              <MobileHeader restricted />
              <NotMember />
              <MobileFooter
                restricted
                onAddUserClick={handleOpenCreateModal}
                onInviteClick={handleOpenInviteModal}
              />
            </MobileContainer>
          ) : (
            <Container>
              <Header restricted />
              <Row fullHeight fullWidth>
                <NotMember />
              </Row>
            </Container>
          )}
        </PermissionProvider>
      </CommunityContext.Provider>
    )
  }

  return (
    <CommunityContext.Provider value={memoizedContext}>
      <GraphProvider
        graphControls={graphControls}
        options={options}
        quickActions={QUICK_ACTIONS}
        setOptions={setOptions}
        showTargetOrganizations
        showTargetTags
        targetUser={me?.graphUser as IGraphPeopleNode}
        useQuickActions
      >
        <PermissionProvider>
          <>
            {isMobile ? (
              <MobileContainer>
                <MobileHeader />
                <Column fullScreen pb="48px" pt="56px">
                  <Outlet />
                </Column>
                <MobileFooter
                  onAddUserClick={handleOpenCreateModal}
                  onInviteClick={handleOpenInviteModal}
                  onOpenNotifications={handleToggleNotifications}
                />
                <CreateCommunityUserModal
                  isOpen={createModal.isOpen}
                  onClose={handleCloseCreateModal}
                />

                <NotificationsContainer fullWidth opened={notificationsOpened}>
                  {notificationsOpened && <NotificationsTable />}
                </NotificationsContainer>
              </MobileContainer>
            ) : (
              <Container>
                <Header
                  invitationsButtonRef={invitationsButtonRef}
                  notificationsButtonRef={notificationsButtonRef}
                  restricted={isRestricted}
                  onOpenInvitePanel={handleOpenInvitePanel}
                  onOpenNotifications={handleToggleNotifications}
                />

                <Content>
                  <NotificationsContainer
                    opened={notificationsOpened}
                    ref={notificationsRef}
                  >
                    {notificationsOpened && <NotificationsTable />}
                  </NotificationsContainer>

                  <InviteTable
                    ref={invitationsRef}
                    tableOpened={tableInviteOpened}
                  >
                    <InvitesTable onOpenInviteModal={handleOpenInviteModal} />
                  </InviteTable>

                  <PageContent>
                    <Outlet />
                  </PageContent>
                </Content>
              </Container>
            )}

            <ProfileModal />

            <InviteUserToCommunityModal
              isOpen={inviteModal.isOpen}
              onClose={handleCloseInviteModal}
            />
          </>
        </PermissionProvider>
      </GraphProvider>
    </CommunityContext.Provider>
  )
}

export default Community
