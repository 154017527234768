import { IoIosArrowDown } from 'react-icons/io'

import styled, { css } from 'styled-components'
import { margin, MarginProps } from 'styled-system'
import { themeGet } from '@styled-system/theme-get'

import {
  circleCheckFilled,
  circlePlusDashedOutline,
  gear,
  personOutline,
} from 'Assets/Svg/icons'

import { Text } from 'Components/UI'

interface IChevronIconOpenCssProps {
  open?: boolean
}
const chevronIconOpenCss = ({ open }: IChevronIconOpenCssProps) =>
  open &&
  css`
    transform: rotate(180deg);
  `
export const ChevronIcon = styled(IoIosArrowDown)`
  margin-left: 4px;
  transition: all 0.3s ease;
  ${chevronIconOpenCss}
`
export const GearIcon = styled(gear)``
export const CirclePlusDashedOutlineIcon = styled(circlePlusDashedOutline)`
  width: 32px;
  height: 32px;
`
export const CircleCheckFilledIcon = styled(circleCheckFilled)`
  width: 14px;
  height: 14px;
`
export const PersonOutlineIcon = styled(personOutline)`
  width: 12px;
  height: 12px;
  margin-right: 4px;
`

export interface IHolderProps extends MarginProps {}

export const Holder = styled.div<IHolderProps>`
  display: flex;
  border-left: 1px solid ${themeGet('colors.text.heading')};
  flex-shrink: 0;
  padding-left: ${themeGet('space.3')}px;
  position: relative;
  z-index: 1000;

  ${margin};
`

export const Dropdown = styled.button<{ isOpen?: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  padding: 10px 8px;
  cursor: pointer;
  border: none;
  background-color: ${props =>
    props.isOpen ? themeGet('colors.bg.tertiary') : 'transparent'};

  &:hover,
  &:focus,
  &:active,
  &[aria-expanded='true'] {
    background-color: ${themeGet('colors.bg.tertiary')};
  }
`

export const DropdownContainer = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  margin-top: 14px;
  margin-left: 14px;
  background-color: #ffffff;
  min-width: 320px;
  border-radius: 4px;
  border: 1px solid #f0f0f0;
  box-shadow:
    0px 0px 0px 1px rgba(0, 0, 0, 0.05),
    0px 10px 15px -3px rgba(0, 0, 0, 0.1),
    0px 4px 6px -2px rgba(0, 0, 0, 0.05);
`

export const DropdownDivider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #f0f0f0;
`

export const CommunityCount = styled(Text)`
  background-color: #f0f0f0;
  border-radius: 20px;
  padding: 0 8px;
`

export const ScrollContainer = styled.ul`
  max-height: 300px;
  overflow-y: auto;
  list-style: none;
  padding: 0;
  margin: 0;
`

export const CommunityItem = styled.li<{ isActive?: boolean }>`
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  gap: 10px;
  padding: 10px;
  border-bottom: 1px solid #f0f0f0;
  background-color: ${props =>
    props.isActive ? themeGet('colors.bg.tertiary') : 'transparent'};

  &:hover,
  &:focus {
    background-color: ${themeGet('colors.bg.tertiary')};
  }
`

export const CommunityLink = styled.a`
  display: grid;
  grid-template-columns: 14px auto 1fr;
  align-items: center;
  gap: 10px;
  text-decoration: none;
  color: inherit;
  cursor: pointer;
`

export const CreateCommunityButton = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 14px 35px 1fr;
  align-items: center;
  gap: 10px;
  padding: 10px;
  cursor: pointer;

  &:hover,
  &:focus {
    background-color: ${themeGet('colors.bg.tertiary')};
  }
`
