import React, { MutableRefObject } from 'react'

import GraphSnapshotContextMenu from 'Features/GraphSnapshot/GraphSnapshotContextMenu'
import Utils from 'Utils'

import NotificationsButton from 'Components/Blocks/Community/NotificationsButton'
import CommunitySelector from 'Components/Blocks/CommunitySelector'
import useControlHandlers from 'Components/Blocks/Graph/GraphControls/useControlHandlers'
import CreateCommunityUserModal from 'Components/Blocks/Modals/CreateCommunityUser'
import SidebarUser from 'Components/Blocks/SidebarUser'
import { Link, Row } from 'Components/UI'

import {
  PERMISSION_ACTION,
  PERMISSION_SCOPES,
  PERMISSION_SUBJECT,
} from 'Constants/permissions'

import {
  useCommunity,
  useEntityModal,
  useGraphContext,
  usePermission,
} from 'Hooks'

import { COMMUNITY_DASHBOARD } from 'Router/routes'

import CreateUserButton from './CreateUserButton'
import InviteManagementButton from './InviteManagementButton'
import { Logo, LogoWrapper, Menu } from './styles'

export interface IHeader {
  menuHidden?: boolean
  restricted?: boolean
  onOpenInvitePanel?: () => void
  onOpenNotifications?: () => void
  notificationsButtonRef?: MutableRefObject<HTMLButtonElement | null>
  invitationsButtonRef?: MutableRefObject<HTMLButtonElement | null>
}

const Header = ({
  menuHidden = false,
  restricted = false,
  onOpenInvitePanel,
  onOpenNotifications,
  notificationsButtonRef,
  invitationsButtonRef,
}: IHeader) => {
  const { community } = useCommunity()
  const { can } = usePermission()

  const [createModal, createActions] = useEntityModal()

  const { graphControls } = useGraphContext()

  const {
    setShowGraphSnapshotsMenu,
    showGraphSnapshotsMenu,
    contextGraphSnapshotsMenuRef,
  } = useControlHandlers()

  const communityDashboardPath = Utils.URL.generatePath(COMMUNITY_DASHBOARD, {
    slug: community?.slug,
  })

  const handleCreateModalOpen = createActions.openModal
  const handleCreateModalClose = createActions.closeModal

  const canCreateUser =
    can(
      PERMISSION_ACTION.CREATE,
      PERMISSION_SUBJECT.ACCOUNT,
      PERMISSION_SCOPES.ACCOUNT_OWN_CREATOR,
    ) && !menuHidden

  const canViewInvites = can(
    PERMISSION_ACTION.READ,
    PERMISSION_SUBJECT.INVITE,
    [],
  )

  return (
    <Menu>
      <Row center fullWidth>
        <LogoLink restricted={restricted} to={communityDashboardPath} />
        {!menuHidden && community && <CommunitySelector ml={3} mr={4} />}

        <Row center gap="10px" ml="auto" noShrink>
          {graphControls?.graphSnapshots && (
            <GraphSnapshotContextMenu
              isOpen={showGraphSnapshotsMenu}
              left="auto"
              ref={contextGraphSnapshotsMenuRef}
              setShowGraphSnapshotsMenu={setShowGraphSnapshotsMenu}
              top="auto"
            />
          )}

          {canCreateUser && (
            <CreateUserButton onClick={handleCreateModalOpen} />
          )}

          {!restricted && !menuHidden && (
            <NotificationsButton
              ref={notificationsButtonRef}
              onOpen={onOpenNotifications}
            />
          )}

          {canViewInvites && !restricted && (
            <InviteManagementButton
              ref={invitationsButtonRef}
              onOpenInvitePanel={onOpenInvitePanel}
            />
          )}

          <SidebarUser />
        </Row>
      </Row>

      <CreateCommunityUserModal
        isOpen={createModal.isOpen}
        onClose={handleCreateModalClose}
      />
    </Menu>
  )
}

const LogoLink = ({ to, restricted }: { to: string; restricted: boolean }) =>
  restricted ? (
    <LogoWrapper>
      <Logo />
    </LogoWrapper>
  ) : (
    <Link to={to}>
      <LogoWrapper>
        <Logo />
      </LogoWrapper>
    </Link>
  )

export default Header
