import { useCallback, useMemo } from 'react'

import { useApolloClient } from '@apollo/client'
import expandKnowledgeQuery from 'Features/KnowledgeGraphing/Queries/ExpandKnowledge.graphql'
import { getUserLabel } from 'Utils/User'

import { take } from 'lodash'
import reduce from 'lodash/reduce'
import values from 'lodash/values'

import { SEARCH_TYPES, SearchType } from 'Constants/ids'
import { TagKind } from 'Constants/mainGraphQL'

import directSearchCommunitySkillsQuery from './Queries/directSearchCommunitySkillsQuery.graphql'
import directSearchCommunityTagsQuery from './Queries/directSearchCommunityTagsQuery.graphql'
import directSearchCommunityUsersQuery from './Queries/directSearchCommunityUsersQuery.graphql'
import directSearchOrganizationsQuery from './Queries/directSearchOrganizations.graphql'
import semanticSearchCommunitySkillsQuery from './Queries/semanticSearchCommunitySkillsQuery.graphql'
import semanticSearchCommunityTagsQuery from './Queries/semanticSearchCommunityTagsQuery.graphql'
import semanticSearchCommunityUsersQuery from './Queries/semanticSearchCommunityUsersQuery.graphql'
import semanticSearchOrganizationsQuery from './Queries/semanticSearchOrganizations.graphql'

export const COMMUNITY_SEARCH_DEBOUNCE = 700
export const COMMUNITY_SEARCH_MINIMUM_LENGTH = 3

export type SearchCommunitySkillsParams = {
  communityId: string
  searchText: string
  limit?: number
}

export type SearchCommunityTagsParams = {
  communityId: string
  searchText: string
  kind: TagKind
  limit?: number
}

export type SearchCommunityUsersParams = {
  communityId: string
  searchText: string
  limit?: number
}

export type SearchCommunityOrganizationsParams = {
  communityId: string
  searchText: string
  limit?: number
}

export enum SearchCommunityType {
  SemanticCustomTags = 'semanticCustomTags',
  DirectCustomTags = 'directCustomTags',
  SemanticEventTags = 'semanticEventTags',
  DirectEventTags = 'directEventTags',
  SemanticGroupTags = 'semanticGroupTags',
  DirectGroupTags = 'directGroupTags',
  SemanticIndustryTags = 'semanticIndustryTags',
  DirectIndustryTags = 'directIndustryTags',
  SemanticProjectTags = 'semanticProjectTags',
  DirectProjectTags = 'directProjectTags',
  SemanticRoleTags = 'semanticRoleTags',
  DirectRoleTags = 'directRoleTags',
  SemanticSkills = 'semanticSkills',
  DirectSkills = 'directSkills',
  SemanticUsers = 'semanticUsers',
  DirectUsers = 'directUsers',
  SemanticOrganizations = 'semanticOrganizations',
  DirectOrganizations = 'directOrganizations',
  ExpandKnowledge = 'expandKnowledge',
}

type ResultOption = {
  id: string
  value: string
  score: number
  type: SearchType
}
type UserResult = {
  option: ResultOption
  node: MainSchema.CommunityUser
}
type SkillResult = {
  option: ResultOption
  node: MainSchema.Skill
}
type TagResult = {
  option: ResultOption
  node: MainSchema.Tag
}
type OrganizationResult = {
  option: ResultOption
  node: MainSchema.Organization
}
export type Result = UserResult | SkillResult | TagResult | OrganizationResult

export type SearchCommunityParams<T extends SearchCommunityType> = {
  communityId: string
  searchText: string
  types: T[]
  limit?: number
}

// TODO: Consider refactoring this to a class instead of a hook in the future
const useCommunitySearch = () => {
  const client = useApolloClient()

  const isSearchTextValid = useCallback((searchText?: string) => {
    return (
      !!searchText &&
      searchText.trim().length >= COMMUNITY_SEARCH_MINIMUM_LENGTH
    )
  }, [])

  const semanticSearchCommunitySkills = useCallback(
    async (params: SearchCommunitySkillsParams) => {
      return client.query<
        Pick<MainSchema.Query, 'semanticSearchCommunitySkills'>,
        MainSchema.QuerySemanticSearchCommunitySkillsArgs
      >({
        query: semanticSearchCommunitySkillsQuery,
        // TODO: update this to support caching, and then update the cache on create
        fetchPolicy: 'no-cache',
        context: {
          batch: true,
          headers: {
            'x-community-id': params.communityId,
          },
        },
        variables: {
          communityId: params.communityId,
          searchText: params.searchText,
          limit: params.limit ?? 10,
        },
      })
    },
    [client],
  )

  const directSearchCommunitySkills = useCallback(
    async (params: SearchCommunitySkillsParams) => {
      return client.query<
        Pick<MainSchema.Query, 'directSearchCommunitySkills'>,
        MainSchema.QueryDirectSearchCommunitySkillsArgs
      >({
        query: directSearchCommunitySkillsQuery,
        // TODO: update this to support caching, and then update the cache on create
        fetchPolicy: 'no-cache',
        context: {
          batch: true,
          headers: {
            'x-community-id': params.communityId,
          },
        },
        variables: {
          communityId: params.communityId,
          query: params.searchText,
          limit: params.limit ?? 10,
        },
      })
    },
    [client],
  )

  const semanticSearchCommunityTags = useCallback(
    async (params: SearchCommunityTagsParams) => {
      return client.query<
        Pick<MainSchema.Query, 'semanticSearchCommunityTags'>,
        MainSchema.QuerySemanticSearchCommunityTagsArgs
      >({
        query: semanticSearchCommunityTagsQuery,
        // TODO: update this to support caching, and then update the cache on create
        fetchPolicy: 'no-cache',
        context: {
          batch: true,
          headers: {
            'x-community-id': params.communityId,
          },
        },
        variables: {
          communityId: params.communityId,
          searchText: params.searchText,
          limit: params.limit ?? 10,
          kind: params.kind,
        },
      })
    },
    [client],
  )

  const directSearchCommunityTags = useCallback(
    async (params: SearchCommunityTagsParams) => {
      return client.query<
        Pick<MainSchema.Query, 'directSearchCommunityTags'>,
        MainSchema.QueryDirectSearchCommunityTagsArgs
      >({
        query: directSearchCommunityTagsQuery,
        // TODO: update this to support caching, and then update the cache on create
        fetchPolicy: 'no-cache',
        context: {
          batch: true,
          headers: {
            'x-community-id': params.communityId,
          },
        },
        variables: {
          communityId: params.communityId,
          query: params.searchText,
          kind: params.kind,
          limit: params.limit ?? 10,
        },
      })
    },
    [client],
  )

  const semanticSearchCommunityUsers = useCallback(
    async (params: SearchCommunityUsersParams) => {
      return client.query<
        Pick<MainSchema.Query, 'semanticSearchCommunityUsers'>,
        MainSchema.QuerySemanticSearchCommunityUsersArgs
      >({
        query: semanticSearchCommunityUsersQuery,
        // TODO: update this to support caching, and then update the cache on create
        fetchPolicy: 'no-cache',
        context: {
          batch: true,
          headers: {
            'x-community-id': params.communityId,
          },
        },
        variables: {
          communityId: params.communityId,
          searchText: params.searchText,
          limit: params.limit ?? 10,
        },
      })
    },
    [client],
  )

  const directSearchCommunityUsers = useCallback(
    async (params: SearchCommunityUsersParams) => {
      return client.query<
        Pick<MainSchema.Query, 'directSearchCommunityUsers'>,
        MainSchema.QueryDirectSearchCommunityUsersArgs
      >({
        query: directSearchCommunityUsersQuery,
        // TODO: update this to support caching, and then update the cache on create
        fetchPolicy: 'no-cache',
        context: {
          batch: true,
          headers: {
            'x-community-id': params.communityId,
          },
        },
        variables: {
          communityId: params.communityId,
          searchText: params.searchText,
          limit: params.limit ?? 10,
        },
      })
    },
    [client],
  )

  const semanticSearchOrganizations = useCallback(
    async (params: SearchCommunityOrganizationsParams) => {
      return client.query<
        Pick<MainSchema.Query, 'semanticSearchOrganizations'>,
        MainSchema.QueryDirectSearchOrganizationsArgs
      >({
        query: semanticSearchOrganizationsQuery,
        // TODO: update this to support caching, and then update the cache on create
        fetchPolicy: 'no-cache',
        context: {
          batch: true,
          headers: {
            'x-community-id': params.communityId,
          },
        },
        variables: {
          communityId: params.communityId,
          query: params.searchText,
          limit: params.limit ?? 10,
        },
      })
    },
    [client],
  )

  const directSearchOrganizations = useCallback(
    async (params: SearchCommunityOrganizationsParams) => {
      return client.query<
        Pick<MainSchema.Query, 'directSearchOrganizations'>,
        MainSchema.QueryDirectSearchOrganizationsArgs
      >({
        query: directSearchOrganizationsQuery,
        // TODO: update this to support caching, and then update the cache on create
        fetchPolicy: 'no-cache',
        context: {
          batch: true,
          headers: {
            'x-community-id': params.communityId,
          },
        },
        variables: {
          communityId: params.communityId,
          query: params.searchText,
          limit: params.limit ?? 10,
        },
      })
    },
    [client],
  )

  const expandKnowledge = useCallback(
    async (origin: string) => {
      return client.query<
        Pick<MainSchema.Query, 'expandKnowledge'>,
        MainSchema.QueryExpandKnowledgeArgs
      >({
        query: expandKnowledgeQuery,
        fetchPolicy: 'no-cache',
        variables: {
          originString: origin,
        },
      })
    },
    [client],
  )

  const searchCommunity = useCallback(
    async <T extends SearchCommunityType>(params: SearchCommunityParams<T>) => {
      const availableSearchFunctions = {
        [SearchCommunityType.SemanticCustomTags]: () =>
          semanticSearchCommunityTags({
            communityId: params.communityId,
            searchText: params.searchText,
            kind: TagKind.Custom,
            limit: params.limit,
          }),
        [SearchCommunityType.DirectCustomTags]: () =>
          directSearchCommunityTags({
            communityId: params.communityId,
            searchText: params.searchText,
            kind: TagKind.Custom,
            limit: params.limit,
          }),
        [SearchCommunityType.SemanticEventTags]: () =>
          semanticSearchCommunityTags({
            communityId: params.communityId,
            searchText: params.searchText,
            kind: TagKind.Event,
            limit: params.limit,
          }),
        [SearchCommunityType.DirectEventTags]: () =>
          directSearchCommunityTags({
            communityId: params.communityId,
            searchText: params.searchText,
            kind: TagKind.Event,
            limit: params.limit,
          }),
        [SearchCommunityType.SemanticGroupTags]: () =>
          semanticSearchCommunityTags({
            communityId: params.communityId,
            searchText: params.searchText,
            kind: TagKind.Group,
            limit: params.limit,
          }),
        [SearchCommunityType.DirectGroupTags]: () =>
          directSearchCommunityTags({
            communityId: params.communityId,
            searchText: params.searchText,
            kind: TagKind.Group,
            limit: params.limit,
          }),
        [SearchCommunityType.SemanticIndustryTags]: () =>
          semanticSearchCommunityTags({
            communityId: params.communityId,
            searchText: params.searchText,
            kind: TagKind.Industry,
            limit: params.limit,
          }),
        [SearchCommunityType.DirectIndustryTags]: () =>
          directSearchCommunityTags({
            communityId: params.communityId,
            searchText: params.searchText,
            kind: TagKind.Industry,
            limit: params.limit,
          }),
        [SearchCommunityType.SemanticProjectTags]: () =>
          semanticSearchCommunityTags({
            communityId: params.communityId,
            searchText: params.searchText,
            kind: TagKind.Project,
            limit: params.limit,
          }),
        [SearchCommunityType.DirectProjectTags]: () =>
          directSearchCommunityTags({
            communityId: params.communityId,
            searchText: params.searchText,
            kind: TagKind.Project,
            limit: params.limit,
          }),
        [SearchCommunityType.SemanticRoleTags]: () =>
          semanticSearchCommunityTags({
            communityId: params.communityId,
            searchText: params.searchText,
            kind: TagKind.Role,
            limit: params.limit,
          }),
        [SearchCommunityType.DirectRoleTags]: () =>
          directSearchCommunityTags({
            communityId: params.communityId,
            searchText: params.searchText,
            kind: TagKind.Role,
            limit: params.limit,
          }),
        [SearchCommunityType.SemanticSkills]: () =>
          semanticSearchCommunitySkills({
            communityId: params.communityId,
            searchText: params.searchText,
            limit: params.limit,
          }),
        [SearchCommunityType.DirectSkills]: () =>
          directSearchCommunitySkills({
            communityId: params.communityId,
            searchText: params.searchText,
            limit: params.limit,
          }),
        [SearchCommunityType.SemanticUsers]: () =>
          semanticSearchCommunityUsers({
            communityId: params.communityId,
            searchText: params.searchText,
            limit: params.limit,
          }),
        [SearchCommunityType.DirectUsers]: () =>
          directSearchCommunityUsers({
            communityId: params.communityId,
            searchText: params.searchText,
            limit: params.limit,
          }),
        [SearchCommunityType.SemanticOrganizations]: () =>
          semanticSearchOrganizations({
            communityId: params.communityId,
            searchText: params.searchText,
            limit: params.limit,
          }),
        [SearchCommunityType.DirectOrganizations]: () =>
          directSearchOrganizations({
            communityId: params.communityId,
            searchText: params.searchText,
            limit: params.limit,
          }),
        [SearchCommunityType.ExpandKnowledge]: () =>
          expandKnowledge(params.searchText),
      }

      const searchTypes = values(params.types)
      const searchFunctions = params.types.map(
        type => availableSearchFunctions[type],
      )
      const searchPromises = values(searchFunctions).map(fn => fn())
      const searchResults = await Promise.all(searchPromises)

      return reduce(
        searchResults,
        (reducedResults, value, index) => ({
          ...reducedResults,
          [searchTypes[index]]: value,
        }),
        {},
      ) as {
        [K in T]: Awaited<ReturnType<(typeof availableSearchFunctions)[K]>>
      }
    },
    [
      semanticSearchCommunitySkills,
      directSearchCommunitySkills,
      semanticSearchCommunityTags,
      directSearchCommunityTags,
      semanticSearchCommunityUsers,
      directSearchCommunityUsers,
      semanticSearchOrganizations,
      directSearchOrganizations,
      expandKnowledge,
    ],
  )

  const processUsers = useCallback(
    (
      directUserEdges: MainSchema.DirectSearchScoredCommunityUser[],
      semanticUserEdges: MainSchema.SemanticSearchScoredCommunityUser[],
      limit?: number,
    ) => {
      const sortedDirectUserEdges = directUserEdges.sort((a, b) => {
        return b.score - a.score
      })
      const sortedSemanticUserEdges = semanticUserEdges.sort((a, b) => {
        return b.score - a.score
      })

      const directUserIds = new Set(
        sortedDirectUserEdges.map(
          sortedDirectUserEdge => sortedDirectUserEdge.communityUser.id,
        ),
      )
      const filteredSemanticUserEdges = sortedSemanticUserEdges.filter(
        sortedSemanticUserEdge =>
          !directUserIds.has(sortedSemanticUserEdge.communityUser.id),
      )

      const directUserResults: UserResult[] = sortedDirectUserEdges.map(
        sortedDirectUserEdge => {
          return {
            option: {
              id: sortedDirectUserEdge.communityUser.userId,
              value: getUserLabel(sortedDirectUserEdge.communityUser),
              score: sortedDirectUserEdge.score,
              type: SEARCH_TYPES.user,
            },
            node: sortedDirectUserEdge.communityUser,
          }
        },
      )

      const semanticUserResults: UserResult[] = filteredSemanticUserEdges.map(
        filteredSemanticUserEdge => {
          return {
            option: {
              id: filteredSemanticUserEdge.communityUser.userId,
              value: getUserLabel(filteredSemanticUserEdge.communityUser),
              score: filteredSemanticUserEdge.score,
              type: SEARCH_TYPES.user,
            },
            node: filteredSemanticUserEdge.communityUser,
          }
        },
      )

      let combinedUserResults = [...directUserResults, ...semanticUserResults]

      if (limit) {
        combinedUserResults = take(combinedUserResults, limit)
      }

      return {
        directUserResults,
        semanticUserResults,
        combinedUserResults,
      }
    },
    [],
  )

  const processSkills = useCallback(
    (
      directSkillEdges: MainSchema.SkillEdge[],
      semanticSkillEdges: MainSchema.SemanticSearchScoredCommunitySkillType[],
      limit?: number,
    ) => {
      const sortedDirectSkillEdges = directSkillEdges.sort((a, b) => {
        return b.score - a.score
      })
      const sortedSemanticSkillEdges = semanticSkillEdges.sort((a, b) => {
        return b.score - a.score
      })

      const directSkillIds = new Set(
        sortedDirectSkillEdges.map(
          sortedDirectSkillEdge => sortedDirectSkillEdge.node.id,
        ),
      )
      const filteredSemanticSkillEdges = sortedSemanticSkillEdges.filter(
        sortedSemanticSkillEdge =>
          !directSkillIds.has(sortedSemanticSkillEdge.skill.id),
      )

      const directSkillResults: SkillResult[] = sortedDirectSkillEdges.map(
        sortedDirectSkillEdge => {
          return {
            option: {
              id: sortedDirectSkillEdge.node.id,
              value: sortedDirectSkillEdge.node.name,
              score: sortedDirectSkillEdge.score,
              type: SEARCH_TYPES.skill,
            },
            node: sortedDirectSkillEdge.node,
          }
        },
      )

      const semanticSkillResults: SkillResult[] =
        filteredSemanticSkillEdges.map(filteredSemanticSkillEdge => {
          return {
            option: {
              id: filteredSemanticSkillEdge.skill.id,
              value: filteredSemanticSkillEdge.skill.name,
              score: filteredSemanticSkillEdge.score,
              type: SEARCH_TYPES.skill,
            },
            node: filteredSemanticSkillEdge.skill,
          }
        })

      let combinedSkillResults = [
        ...directSkillResults,
        ...semanticSkillResults,
      ]

      if (limit) {
        combinedSkillResults = take(combinedSkillResults, limit)
      }

      return {
        directSkillResults,
        semanticSkillResults,
        combinedSkillResults,
      }
    },
    [],
  )

  const processTags = useCallback(
    (
      directTagEdges: MainSchema.TagEdge[],
      semanticTagEdges: MainSchema.SemanticSearchScoredCommunityTagType[],
      limit?: number,
    ) => {
      const sortedDirectTagEdges = directTagEdges.sort((a, b) => {
        return b.score - a.score
      })
      const sortedSemanticTagEdges = semanticTagEdges.sort((a, b) => {
        return b.score - a.score
      })

      const directTagIds = new Set(
        sortedDirectTagEdges.map(
          sortedDirectTagEdge => sortedDirectTagEdge.node.id,
        ),
      )
      const filteredSemanticTagEdges = sortedSemanticTagEdges.filter(
        sortedSemanticTagEdge =>
          !directTagIds.has(sortedSemanticTagEdge.tag.id),
      )

      const directTagResults: TagResult[] = sortedDirectTagEdges.map(
        sortedDirectTagEdge => {
          return {
            option: {
              id: sortedDirectTagEdge.node.id,
              value: sortedDirectTagEdge.node.name,
              score: sortedDirectTagEdge.score,
              type: sortedDirectTagEdge.node.kind,
            },
            node: sortedDirectTagEdge.node,
          }
        },
      )

      const semanticTagResults: TagResult[] = filteredSemanticTagEdges.map(
        filteredSemanticTagEdge => {
          return {
            option: {
              id: filteredSemanticTagEdge.tag.id,
              value: filteredSemanticTagEdge.tag.name,
              score: filteredSemanticTagEdge.score,
              type: filteredSemanticTagEdge.tag.kind,
            },
            node: filteredSemanticTagEdge.tag,
          }
        },
      )

      let combinedTagResults = [...directTagResults, ...semanticTagResults]

      if (limit) {
        combinedTagResults = take(combinedTagResults, limit)
      }

      return {
        directTagResults,
        semanticTagResults,
        combinedTagResults,
      }
    },
    [],
  )

  const processOrganizations = useCallback(
    (
      directOrganizationEdges: MainSchema.OrganizationEdge[],
      semanticOrganizationEdges: MainSchema.OrganizationEdge[],
      limit?: number,
    ) => {
      const sortedDirectOrganizationEdges = directOrganizationEdges.sort(
        (a, b) => {
          return b.score - a.score
        },
      )
      const sortedSemanticOrganizationEdges = semanticOrganizationEdges.sort(
        (a, b) => {
          return b.score - a.score
        },
      )

      const directOrganizationIds = new Set(
        sortedDirectOrganizationEdges.map(
          sortedDirectOrganizationEdge => sortedDirectOrganizationEdge.node.id,
        ),
      )
      const filteredSemanticOrganizationEdges =
        sortedSemanticOrganizationEdges.filter(
          sortedSemanticOrganizationEdge =>
            !directOrganizationIds.has(sortedSemanticOrganizationEdge.node.id),
        )

      const directOrganizationResults: OrganizationResult[] =
        sortedDirectOrganizationEdges.map(sortedDirectOrganizationEdge => {
          return {
            option: {
              id: sortedDirectOrganizationEdge.node.id,
              value: sortedDirectOrganizationEdge.node.name,
              score: sortedDirectOrganizationEdge.score,
              type: SEARCH_TYPES.organization,
            },
            node: sortedDirectOrganizationEdge.node,
          }
        })

      const semanticOrganizationResults: OrganizationResult[] =
        filteredSemanticOrganizationEdges.map(
          filteredSemanticOrganizationEdge => {
            return {
              option: {
                id: filteredSemanticOrganizationEdge.node.id,
                value: filteredSemanticOrganizationEdge.node.name,
                score: filteredSemanticOrganizationEdge.score,
                type: SEARCH_TYPES.organization,
              },
              node: filteredSemanticOrganizationEdge.node,
            }
          },
        )

      let combinedOrganizationResults = [
        ...directOrganizationResults,
        ...semanticOrganizationResults,
      ]

      if (limit) {
        combinedOrganizationResults = take(combinedOrganizationResults, limit)
      }

      return {
        directOrganizationResults,
        semanticOrganizationResults,
        combinedOrganizationResults,
      }
    },
    [],
  )

  const processTopResults = useCallback(
    (directResults: Result[], semanticResults: Result[], limit?: number) => {
      const sortedDirectResults = directResults.sort((a, b) => {
        return b.option.score - a.option.score
      })
      const sortedSemanticResults = semanticResults.sort((a, b) => {
        return b.option.score - a.option.score
      })

      const directIds = new Set(
        sortedDirectResults.map(
          sortedDirectResult => sortedDirectResult.option.id,
        ),
      )
      const filteredSemanticResults = sortedSemanticResults.filter(
        sortedSemanticResult => !directIds.has(sortedSemanticResult.option.id),
      )

      let combinedResults = [...sortedDirectResults, ...filteredSemanticResults]

      if (limit) {
        combinedResults = take(combinedResults, limit)
      }

      return {
        directResults,
        semanticResults,
        combinedResults,
      }
    },
    [],
  )

  const communitySearch = useMemo(() => {
    return {
      isSearchTextValid,
      semanticSearchCommunitySkills,
      directSearchCommunitySkills,
      semanticSearchCommunityTags,
      directSearchCommunityTags,
      semanticSearchCommunityUsers,
      directSearchCommunityUsers,
      semanticSearchOrganizations,
      directSearchOrganizations,
      expandKnowledge,
      searchCommunity,
      processUsers,
      processSkills,
      processTags,
      processOrganizations,
      processTopResults,
    }
  }, [
    isSearchTextValid,
    semanticSearchCommunitySkills,
    directSearchCommunitySkills,
    semanticSearchCommunityTags,
    directSearchCommunityTags,
    semanticSearchCommunityUsers,
    directSearchCommunityUsers,
    semanticSearchOrganizations,
    directSearchOrganizations,
    expandKnowledge,
    searchCommunity,
    processUsers,
    processSkills,
    processTags,
    processOrganizations,
    processTopResults,
  ])

  return communitySearch
}

export default useCommunitySearch
