import { IoIosArrowDown } from 'react-icons/io'

import styled, { css } from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

import { Text } from 'Components/UI'

interface IChevronIconOpenCssProps {
  open?: boolean
}
const chevronIconOpenCss = ({ open }: IChevronIconOpenCssProps) =>
  open &&
  css`
    transform: rotate(180deg);
  `
export const ChevronIcon = styled(IoIosArrowDown)`
  margin-left: 4px;
  transition: all 0.3s ease;
  ${chevronIconOpenCss}
`

export const Dropdown = styled.button<{ isOpen?: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  padding: 10px 8px;
  cursor: pointer;
  border: none;
  background-color: ${props =>
    props.isOpen ? themeGet('colors.bg.tertiary') : 'transparent'};

  &:hover,
  &:focus,
  &:active,
  &[aria-expanded='true'] {
    background-color: ${themeGet('colors.bg.tertiary')};
  }
`

export const DropdownText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: 2px;
`

export const DropdownDivider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #f0f0f0;
`

export const MenuItemHeader = styled(Text)`
  display: grid;
  grid-template-columns: 12px 1fr 12px;
  align-items: center;
  gap: ${themeGet('space.2')}px;
  padding: ${themeGet('space.2')}px ${themeGet('space.2')}px 0;
  font-weight: ${themeGet('fontWeight.0')};
  font-size: ${themeGet('fontSize.0')}px;
  color: ${themeGet('colors.text.placeholder')};
  cursor: default;
  white-space: nowrap;
`
