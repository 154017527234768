import { useCallback } from 'react'

import { useMutation } from '@apollo/client'
import useAbility from 'Features/Notes/useAbility'
import useUserPermissions from 'Features/User/useUserPermissions'
import updateCommunityUsersStatusMutation from 'GraphQL/Mutations/Community/updateCommunityUsersStatus.graphql'

import { QUICK_ACTION_KIND, QuickActionKind } from 'Constants/graph'
import { CommunityUserStatus } from 'Constants/mainGraphQL'
import {
  PERMISSION_ACTION,
  PERMISSION_SCOPES,
  PERMISSION_SUBJECT,
} from 'Constants/permissions'

import { useAppContext, useCommunity, usePermission } from 'Hooks'

import EventBus from 'Services/EventBus'
import { useScopedI18n } from 'Services/I18n'
import toast from 'Services/Toast'

import { AddSkillTagFormField } from '../Graph/QuickActions/AddSkillTagPanel'

interface ActionHandlerParams {
  user: MainSchema.GraphUser
  users: MainSchema.GraphUser[]
}

export interface IActionHandler {
  onClick: (params: ActionHandlerParams) => void
  isEnabled: (params: ActionHandlerParams) => boolean
}

export interface IUseActionHandlers {
  quickActions: QuickActionKind[]
}

const EDIT_ALLOWED_SELECTION = 1
const VIEW_PROFILE_ALLOWED_SELECTION = 1
const PATH_FIND_ALLOWED_SELECTION = 1
const CREATE_NOTE_ALLOWED_SELECTION = 1
const INTRODUCE_TO_ALLOWED_SELECTION = 2

export default function useActionHandlers({
  quickActions,
}: IUseActionHandlers): Record<QuickActionKind, IActionHandler> {
  const { me } = useAppContext()
  const { canCreate } = useAbility()
  const { canEditUser } = useUserPermissions()
  const { can } = usePermission()
  const s = useScopedI18n('user.actions.archive')
  const { community } = useCommunity()

  const [updateCommunityUsersStatus] = useMutation<
    Pick<MainSchema.Mutation, 'updateCommunityUsersStatus'>,
    MainSchema.MutationUpdateCommunityUsersStatusArgs
  >(updateCommunityUsersStatusMutation)

  const canCreateNotes = useCallback(
    (targetUser: MainSchema.GraphUser) => {
      return canCreate(targetUser.id!)
    },
    [canCreate],
  )

  const handleViewProfile = useCallback(({ user }: ActionHandlerParams) => {
    EventBus.trigger(
      EventBus.actions.profile.openRightUserPanelProfile,
      user?.id,
    )
  }, [])

  const canViewProfile = useCallback(
    ({ users }: ActionHandlerParams) =>
      users.length === VIEW_PROFILE_ALLOWED_SELECTION &&
      quickActions.includes(QUICK_ACTION_KIND.VIEW_PROFILE),
    [quickActions],
  )

  const handlePathFind = useCallback(({ user }: ActionHandlerParams) => {
    EventBus.trigger(EventBus.actions.graph.findPath, user?.id)
  }, [])

  const canPathFind = useCallback(
    ({ user, users }: ActionHandlerParams) =>
      users.length === PATH_FIND_ALLOWED_SELECTION &&
      user?.id !== me?.id &&
      quickActions.includes(QUICK_ACTION_KIND.PATH_TO_USER),
    [me, quickActions],
  )

  const handleCreateNote = useCallback(({ user }: ActionHandlerParams) => {
    EventBus.trigger(EventBus.actions.dashboard.createQuickNote, user)
  }, [])

  const canCreateNote = useCallback(
    ({ user, users }: ActionHandlerParams) =>
      canCreateNotes(user) &&
      users.length === CREATE_NOTE_ALLOWED_SELECTION &&
      quickActions.includes(QUICK_ACTION_KIND.CREATE_NOTE),
    [canCreateNotes, quickActions],
  )

  const handleIntroduceTo = useCallback(({ users }: ActionHandlerParams) => {
    EventBus.trigger(EventBus.actions.dashboard.introduceTo, users)
    EventBus.trigger(EventBus.actions.dashboard.closePopover)
  }, [])

  const canIntroduce = useCallback(
    ({ user, users }: ActionHandlerParams) => {
      const isMe = user?.id === me?.id

      return (
        !isMe &&
        users.length <= INTRODUCE_TO_ALLOWED_SELECTION &&
        quickActions.includes(QUICK_ACTION_KIND.INTRODUCE_TO)
      )
    },
    [me, quickActions],
  )

  const handleOpenAddToCommunityModal = useCallback(
    ({ users }: ActionHandlerParams) => {
      EventBus.trigger(EventBus.actions.dashboard.addToCommunity, users)
    },
    [],
  )

  const canAddToCommunity = useCallback(
    ({ users }: ActionHandlerParams) => {
      const hasMe = users?.some(user => user?.id === me?.id)
      return !hasMe && quickActions.includes(QUICK_ACTION_KIND.ADD_TO_COMMUNITY)
    },
    [me, quickActions],
  )

  const handleOpenTagsToUsersModal = useCallback(
    ({ users }: ActionHandlerParams) => {
      EventBus.trigger(EventBus.actions.dashboard.addTagsToUser, {
        [AddSkillTagFormField.Users]: users,
      })
    },
    [],
  )

  const canTagUser = useCallback(
    () => quickActions.includes(QUICK_ACTION_KIND.ADD_SKILLS_TAGS),
    [quickActions],
  )

  const handleEdit = useCallback(({ user }: ActionHandlerParams) => {
    EventBus.trigger(EventBus.actions.dashboard.updateContact, user)
  }, [])

  const canEdit = useCallback(
    ({ user, users }: ActionHandlerParams) => {
      return (
        users.length === EDIT_ALLOWED_SELECTION &&
        quickActions.includes(QUICK_ACTION_KIND.EDIT) &&
        canEditUser(user)
      )
    },
    [canEditUser, quickActions],
  )

  const handleArchive = useCallback(
    async ({ user }: ActionHandlerParams) => {
      try {
        if (!user?.id || !community?.id)
          throw new Error('Invalid user or community')

        await updateCommunityUsersStatus({
          variables: {
            // We should eventually get communityId from the caller
            communityId: community.id,
            userIds: [user.id],
            status: CommunityUserStatus.Archived,
          },
        })
        toast.success({
          title: s('title'),
          text: s('updateSuccess'),
        })
        EventBus.trigger(EventBus.actions.graph.removeItemsByIds, [user.id])
        EventBus.trigger(EventBus.actions.profile.closeRightUserPanelProfile)
        EventBus.trigger(EventBus.actions.dashboard.refetch)
      } catch (error: any) {
        toast.error({
          title: s('title'),
          text: error.message,
        })
      }
    },
    [community?.id, s, updateCommunityUsersStatus],
  )

  const canArchive = useCallback(
    ({ user }: ActionHandlerParams) => {
      return (
        user?.id !== me?.id &&
        user.communityUserStatus !== CommunityUserStatus.Full &&
        user.communityUserStatus !== CommunityUserStatus.Archived &&
        (can(
          PERMISSION_ACTION.DELETE,
          PERMISSION_SUBJECT.ACCOUNT,
          PERMISSION_SCOPES.ACCOUNT_OTHER_CREATOR,
        ) ||
          can(
            PERMISSION_ACTION.DELETE,
            PERMISSION_SUBJECT.ACCOUNT,
            PERMISSION_SCOPES.ACCOUNT_OWN_CREATOR,
          ))
      )
    },
    [can, me?.id],
  )

  const handleHide = useCallback(() => {
    EventBus.trigger(EventBus.actions.graph.removeSelected)
  }, [])

  const canHide = useCallback(
    () => quickActions.includes(QUICK_ACTION_KIND.HIDE),
    [quickActions],
  )

  const handleViewSelectedItems = useCallback(() => {
    EventBus.trigger(EventBus.actions.graph.viewSelected)
  }, [])

  const canViewSelectedItems = useCallback(
    () => quickActions.includes(QUICK_ACTION_KIND.VIEW_SELECTED_ITEMS),
    [quickActions],
  )

  return {
    [QUICK_ACTION_KIND.QUICK_CONNECTOR]: {
      onClick: () => {},
      isEnabled: () => true,
    },
    [QUICK_ACTION_KIND.VIEW_PROFILE]: {
      onClick: handleViewProfile,
      isEnabled: canViewProfile,
    },
    [QUICK_ACTION_KIND.PATH_TO_USER]: {
      onClick: handlePathFind,
      isEnabled: canPathFind,
    },
    [QUICK_ACTION_KIND.CREATE_NOTE]: {
      onClick: handleCreateNote,
      isEnabled: canCreateNote,
    },
    [QUICK_ACTION_KIND.INTRODUCE_TO]: {
      onClick: handleIntroduceTo,
      isEnabled: canIntroduce,
    },
    [QUICK_ACTION_KIND.ADD_TO_COMMUNITY]: {
      onClick: handleOpenAddToCommunityModal,
      isEnabled: canAddToCommunity,
    },
    [QUICK_ACTION_KIND.ADD_SKILLS_TAGS]: {
      onClick: handleOpenTagsToUsersModal,
      isEnabled: canTagUser,
    },
    [QUICK_ACTION_KIND.EDIT]: {
      onClick: handleEdit,
      isEnabled: canEdit,
    },
    [QUICK_ACTION_KIND.ARCHIVE]: {
      onClick: handleArchive,
      isEnabled: canArchive,
    },
    [QUICK_ACTION_KIND.HIDE]: {
      onClick: handleHide,
      isEnabled: canHide,
    },
    [QUICK_ACTION_KIND.VIEW_SELECTED_ITEMS]: {
      onClick: handleViewSelectedItems,
      isEnabled: canViewSelectedItems,
    },
  }
}
