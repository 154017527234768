import React, { useRef, useState } from 'react'

import Utils from 'Utils'

import { CommunitySelectorModal } from 'Components/Blocks/Mobile/Modals'
import { Link, Row, Text } from 'Components/UI'

import { EXTERNAL_LINKS } from 'Constants/ids'

import { useCommunityContext, useOnClickOutside } from 'Hooks'

import {
  COMMUNITY_MANAGEMENT,
  COMMUNITY_PANEL,
  ROOT,
  SIGN_OUT,
} from 'Router/routes'

import {
  CloseIcon,
  Container,
  DownIcon,
  Logo,
  LogoWrapper,
  MenuButton,
  MenuContainer,
  MenuIcon,
  MenuLink,
} from './styles'

export interface IHeaderProps {
  menuHidden?: boolean
  restricted?: boolean
}

function Header({ menuHidden = false, restricted = false }: IHeaderProps) {
  const ref = useRef<HTMLDivElement | null>(null)
  const { community } = useCommunityContext()

  const [isMenuOpen, setMenuOpen] = useState(false)
  const [isShowCommunityModal, setShowCommunityModal] = useState(false)

  useOnClickOutside(ref, () => setMenuOpen(false))

  const handleLinkClick: React.MouseEventHandler = () => {
    setMenuOpen(false)
  }

  return (
    <Container ref={ref}>
      <CommunitySelectorModal
        isOpen={isShowCommunityModal}
        title="Change Network"
        onClose={() => setShowCommunityModal(false)}
      />

      <Row center fullWidth px="13px" py="16px" spaceBetween>
        <Link to={ROOT}>
          <LogoWrapper>
            <Logo />
          </LogoWrapper>
        </Link>
        {community?.name && !menuHidden && (
          <MenuButton gap={3} onClick={() => setShowCommunityModal(true)}>
            <Text as="span" fontSize={2}>
              {community.name}
            </Text>
            <DownIcon />
          </MenuButton>
        )}

        {!menuHidden && (
          <MenuButton onClick={() => setMenuOpen(prevState => !prevState)}>
            {isMenuOpen ? <CloseIcon /> : <MenuIcon />}
          </MenuButton>
        )}
      </Row>

      {isMenuOpen && (
        <MenuContainer gap={4} px="13px" py="16px">
          <MenuLink secondary to={COMMUNITY_PANEL} onClick={handleLinkClick}>
            Community
          </MenuLink>

          {!restricted && (
            <MenuLink
              secondary
              to={Utils.URL.generatePath(COMMUNITY_MANAGEMENT, {
                slug: community?.slug,
              })}
              onClick={handleLinkClick}
            >
              Management
            </MenuLink>
          )}

          <MenuLink href={EXTERNAL_LINKS.termsOfUse} isExternal secondary>
            Terms of Use
          </MenuLink>

          <MenuLink href={EXTERNAL_LINKS.privacyPolicy} isExternal secondary>
            Privacy Policy
          </MenuLink>

          <MenuLink secondary to={SIGN_OUT} onClick={handleLinkClick}>
            Log out
          </MenuLink>
        </MenuContainer>
      )}
    </Container>
  )
}

export default Header
