import React, {
  ChangeEvent,
  MouseEvent,
  PropsWithChildren,
  useRef,
  useState,
} from 'react'

import { MdCheck } from 'react-icons/md'

import * as icons from 'Assets/Svg/icons'

import Switch from 'Components/UI/Form/Switch/Switch'

import * as Styled from './styles'

export interface IMenuItem extends PropsWithChildren {
  id: string
  label: string
  isSelected?: boolean
  useToggle?: boolean
  isToggled?: boolean
  isDefault?: boolean
  canBeFavorited?: boolean
  areChildrenScrollable?: boolean
  showDivider?: boolean
  iconPrefix?: Styled.IconType
  onClick?: (
    event: MouseEvent<HTMLDivElement> | ChangeEvent<Element>,
    id: string,
  ) => void
}

function MenuItem({
  children,
  label,
  id,
  isSelected = false,
  useToggle = false,
  isToggled = false,
  isDefault = false,
  canBeFavorited = false,
  showDivider = true,
  iconPrefix,
  areChildrenScrollable,
  onClick,
}: IMenuItem) {
  const itemRef = useRef(null)
  const [isHovered, setIsHovered] = useState(false)

  const renderIconPrefix = () => {
    if (isSelected) {
      return <MdCheck size={16} />
    }
    if (canBeFavorited) {
      return <div style={{ width: 12 }} />
    }
    if (iconPrefix) {
      const IconComponent = icons[iconPrefix as Styled.IconType]
      return (
        <Styled.StyledIcon>
          <IconComponent />
        </Styled.StyledIcon>
      )
    }
    return null
  }

  const renderIconSuffix = () => {
    if (!useToggle && React.Children.count(children) === 0 && canBeFavorited) {
      let iconType
      switch (true) {
        case isDefault:
          iconType = 'starFilled'
          break
        case isHovered:
          iconType = 'starOutline'
          break
        default:
          return null
      }
      const IconComponent = icons[iconType as Styled.IconType]
      return (
        <Styled.StyledIcon>
          <IconComponent />
        </Styled.StyledIcon>
      )
    }
    return <div />
  }

  return (
    <Styled.Item
      ref={itemRef}
      showDivider={showDivider}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {renderIconPrefix()}
      <Styled.ItemLabel onClick={event => onClick?.(event, id)}>
        {label}
      </Styled.ItemLabel>
      {renderIconSuffix()}

      {useToggle && (
        <Switch checked={isToggled} onChange={event => onClick?.(event, id)} />
      )}

      {!useToggle && React.Children.count(children) > 0 && (
        <Styled.MenuUnfold />
      )}

      {isHovered && React.Children.count(children) > 0 && (
        <Styled.Menu hovered scrollable={areChildrenScrollable}>
          {children}
        </Styled.Menu>
      )}
    </Styled.Item>
  )
}

export default MenuItem
