import { useCallback } from 'react'

import { useApolloClient } from '@apollo/client'
import { IGraphPeopleNode } from 'Features/GraphNodes/NodeTypes'
import { useGraphLoaders } from 'Features/GraphNodes/useGraphLoader'
import { onKnowledgeNodeDoubleClick } from 'Features/KnowledgeGraphing/Graph/onKnowledgeNodeDoubleClick'
import communityPathToUserQuery from 'GraphQL/Queries/Community/communityPathToUser.graphql'
import communityUserConnectionsByDegreesQuery from 'GraphQL/Queries/Community/communityUserConnectionsByDegrees.graphql'
import getCommunityUserQuery from 'GraphQL/Queries/CommunityUser/getCommunityUser.graphql'
import getOrganizationQuery from 'GraphQL/Queries/Organization/getOrganization.graphql'
import PQueue from 'p-queue'

import isEmpty from 'lodash/isEmpty'
import keys from 'lodash/keys'

import { NODE_KIND, NodeKind } from 'Constants/graph'
import { SEARCH_TYPES } from 'Constants/ids'
import { KnowledgeGraphNodeKind } from 'Constants/mainGraphQL'

import { useAppContext, useCommunityContext } from 'Hooks'
import useEventBusSubscribe from 'Hooks/useEventBusSubscribe'
import {
  AppendItemsHandler,
  IGraphQuery,
  IGraphState,
  TemporaryConnectUserHandler,
} from 'Hooks/useGraphContext'

import EventBus from 'Services/EventBus'

import { ITagNode } from './utils'

export interface ICommunityTags {
  count: number
  rows: MainSchema.Tag[]
}

export interface IOptions {
  users: string[]
  skills: string[]
  needSkills: string[]
  organizations: string[]
  communities: string[]
  tags: string[]
  knowledge: string[]
}

export interface IUseGraphQuery {
  options?: IOptions
  setOptions?: React.Dispatch<React.SetStateAction<IOptions | undefined>>
  setPaths: React.Dispatch<React.SetStateAction<IGraphPeopleNode[][]>>
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
  setGraphState: React.Dispatch<React.SetStateAction<IGraphState>>
  handleAppendItems: AppendItemsHandler
  handleTemporaryConnectUser: TemporaryConnectUserHandler
}

export interface IItem {
  id: string
  label: string
  type?: NodeKind
  photoUrl?: string
  // TODO: refactor this to be more specific
  value?: any
}

// Only queries for loading items in the background, or loading users, skills, tags, organizations, paths, etc. into the graph
export default function useGraphQuery({
  options,
  setOptions,
  setPaths,
  setIsLoading,
  setGraphState,
  handleAppendItems,
  handleTemporaryConnectUser,
}: IUseGraphQuery): IGraphQuery {
  const { community } = useCommunityContext()
  const { me } = useAppContext()
  const client = useApolloClient()
  const { loadPeople, loadTags } = useGraphLoaders()

  const communityId = community?.id

  const handleExpandUser = useCallback(
    async ({
      userId,
      connectedUserIds,
    }: {
      userId: string
      connectedUserIds: string[]
    }) => {
      if (!communityId) {
        return
      }

      setIsLoading(true)

      // Load the selected users full profile, users loaded for graphing are only partial profiles
      const selectedUser = await client.query<
        Pick<MainSchema.Query, 'getCommunityUser'>,
        MainSchema.QueryCommunityUserArgs
      >({
        query: getCommunityUserQuery,
        variables: {
          communityId,
          userId,
        },
      })

      const expandedUser = selectedUser?.data?.getCommunityUser

      const userIds = [userId, ...connectedUserIds]

      const result = await loadPeople({
        communityIds: [communityId],
        limit: userIds.length,
        userIds,
      })

      setIsLoading(false)

      const users = result?.nodes || []
      const organizations = [
        ...(expandedUser?.workHistory
          ?.filter(workHistory => workHistory.isCurrent)
          ?.map(workHistory => workHistory.organization) ?? []),
      ] as MainSchema.Organization[]

      handleAppendItems({
        users,
        organizations,
      })
    },
    [communityId, setIsLoading, client, loadPeople, handleAppendItems],
  )

  const handleSearch = useCallback(
    async ({
      limit = 25,
      users = [],
      skills = [],
      tags = [],
      organizations = [],
      communities = [],
      forceReload = false,
    }: {
      limit?: number
      users?: string[]
      skills?: string[]
      tags?: string[]
      organizations?: string[]
      communities?: string[]
      forceReload?: boolean
    }) => {
      if (!communityId) {
        return
      }

      setIsLoading(true)

      const result = await loadPeople({
        communityIds: [...communities, communityId],
        limit,
        userIds: users,
        skillIds: skills,
        tagIds: tags,
        workHistoryOrganizationIds: organizations,
        educationHistoryOrganizationIds: organizations,
        forceReload,
      })

      setGraphState(prevState => ({
        ...prevState,
        appendUsers: result?.nodes || [],
      }))

      setIsLoading(false)
    },
    [communityId, loadPeople, setGraphState, setIsLoading],
  )

  const handleAddOrganization = useCallback(
    async ({
      id,
      isSelected = false,
      isMultiSelect = false,
    }: {
      id: string
      isSelected?: boolean
      isMultiSelect?: boolean
    }) => {
      setIsLoading(true)

      const result = await client.query<
        Pick<MainSchema.Query, 'organization'>,
        MainSchema.QueryOrganizationArgs
      >({
        query: getOrganizationQuery,
        fetchPolicy: 'network-only',
        variables: {
          id,
        },
      })

      const organization = result?.data?.organization

      if (organization) {
        handleAppendItems({
          organizations: [organization],
        })

        // Using timeout here to have an updated state that is handled by handleSearch, this has impact on updateUserSelectionAndActions at useAction.js hook,
        // Without using timeout updateUserSelectionAndActions will have an outdated state because the selection will be updated earlier than handleSearch state
        setTimeout(() => {
          if (isSelected && isMultiSelect)
            setGraphState(prevState => ({
              ...prevState,
              selection: { ...prevState.selection, [organization.id]: true },
            }))
          else if (isSelected)
            setGraphState(prevState => ({
              ...prevState,
              selection: { [organization.id]: true },
            }))
        }, 0)
      }

      setIsLoading(false)
    },
    [setIsLoading, client, handleAppendItems, setGraphState],
  )

  const handleExpandKnowledge = useCallback(
    async (node: MainSchema.KnowledgeGraphNode) => {
      setIsLoading(true)
      try {
        const nodes = await onKnowledgeNodeDoubleClick(
          client,
          node,
          communityId,
        )
        if (nodes && nodes.length > 0) {
          handleAppendItems({ knowledge: nodes })
        }
        setIsLoading(false)
      } finally {
        setIsLoading(false)
      }
    },
    [client, communityId, handleAppendItems, setIsLoading],
  )

  const handleCommunitySearch = useCallback(
    async (item: IItem) => {
      const innerOptions: IOptions = {
        users: [],
        skills: [],
        needSkills: [],
        organizations: [],
        communities: [],
        tags: [],
        knowledge: [],
      }

      if (item?.type === SEARCH_TYPES.skill) {
        innerOptions.skills.push(item.id)
        handleAppendItems({ skills: [{ id: item.id, name: item.label }] })
      }

      if (item?.type === SEARCH_TYPES.community) {
        innerOptions.communities.push(item.id)
        handleAppendItems({
          communities: [
            { id: item.id, name: item.label, photoUrl: item.photoUrl },
          ],
        })
      }

      if (item?.type === SEARCH_TYPES.organization) {
        handleAddOrganization({
          id: item.id,
        })
      }

      if (item?.type === SEARCH_TYPES.knowledge) {
        innerOptions.knowledge.push(item.id)
        handleAppendItems({
          knowledge: [
            {
              id: item.id,
              valueString: item.label,
              kind: KnowledgeGraphNodeKind.Topic,
            },
          ],
        })
      }

      if (item?.type === SEARCH_TYPES.user) {
        handleSearch({
          limit: 1,
          users: [item.id],
        })
      }

      if (
        item?.type === SEARCH_TYPES.role ||
        item?.type === SEARCH_TYPES.event ||
        item?.type === SEARCH_TYPES.project ||
        item?.type === SEARCH_TYPES.group ||
        item?.type === SEARCH_TYPES.custom
      ) {
        innerOptions.tags.push(item.id)
        handleAppendItems({
          tags: [{ id: item.id, name: item.label, kind: item.type }],
        })
      }

      setOptions?.({ ...options, ...innerOptions })
    },
    [
      handleAppendItems,
      handleAddOrganization,
      handleSearch,
      options,
      setOptions,
    ],
  )

  const handleSearchByDegrees = useCallback(
    async ({ userId, degrees }: { userId: string; degrees: number }) => {
      if (!communityId) {
        return
      }

      setIsLoading(true)

      const result = await client.query<
        Pick<MainSchema.Query, 'communityUserConnectionsByDegrees'>,
        MainSchema.QueryCommunityUserConnectionsByDegreesArgs
      >({
        query: communityUserConnectionsByDegreesQuery,
        fetchPolicy: 'network-only',
        variables: {
          userId,
          communityId,
          degrees,
        },
      })

      const connectionsByDegrees = result?.data
        ?.communityUserConnectionsByDegrees as IGraphPeopleNode[]

      setIsLoading(false)

      setGraphState(prevState => ({
        ...prevState,
        appendUsers: connectionsByDegrees || [],
      }))
    },
    [client, communityId, setGraphState, setIsLoading],
  )

  const handleLoadPath = useCallback(
    async (userId?: string) => {
      if (!userId || !communityId) return []

      const result = await client.query<
        Pick<MainSchema.Query, 'communityPathToUser'>,
        MainSchema.QueryCommunityPathToUserArgs
      >({
        query: communityPathToUserQuery,
        variables: {
          userId,
          communityId,
        },
      })

      const path = result.data?.communityPathToUser

      return isEmpty(path) ? [] : path
    },
    [client, communityId],
  )

  const handleFindPath = useCallback(
    async (userId?: string) => {
      const userPaths = (await handleLoadPath(userId)) as IGraphPeopleNode[]
      if (userPaths?.length > 0) {
        setPaths([userPaths])
      } else {
        setPaths([])
      }
    },
    [handleLoadPath, setPaths],
  )

  const handleAddUserById = useCallback(
    async ({
      userId,
      isSelected = false,
      isMultiSelect = false,
      fromUserId,
    }: {
      userId: string
      isSelected?: boolean
      isMultiSelect?: boolean
      fromUserId?: string
    }) => {
      await handleSearch({ limit: 1, users: [userId] })

      if (fromUserId) {
        // Add fake connection to the graph
        handleTemporaryConnectUser({ fromUserId, toUserId: userId })
      }

      // Using timeout here to have an updated state that is handled by handleSearch, this has impact on updateUserSelectionAndActions at useAction.js hook,
      // Without using timeout updateUserSelectionAndActions will have an outdated state because the selection will be updated earlier than handleSearch state
      setTimeout(() => {
        if (isSelected && isMultiSelect)
          setGraphState(prevState => ({
            ...prevState,
            selection: { ...prevState.selection, [userId]: true },
          }))
        else if (isSelected)
          setGraphState(prevState => ({
            ...prevState,
            selection: { [userId]: true },
          }))
      }, 0)
    },
    [handleSearch, setGraphState, handleTemporaryConnectUser],
  )

  const handleAddUsersById = useCallback(
    async ({
      userIds,
      forceLayoutReset = true,
      forceReload = false,
    }: {
      userIds: string[]
      forceLayoutReset?: boolean
      forceReload?: boolean
    }) => {
      if (userIds.length === 0) {
        return
      }

      await handleSearch({
        limit: userIds.length,
        users: userIds,
        forceReload,
      })

      setGraphState(prevState => ({
        ...prevState,
        forceLayoutReset,
      }))
    },
    [handleSearch, setGraphState],
  )

  const handleMyNetwork = useCallback(
    (degrees: number) => {
      const connectedUsers = keys(me?.graphUser?.connectedUsers || {})

      // No need to perform complex search for 1st degrees, we already know the Ids
      if (connectedUsers?.length && degrees === 1) {
        handleSearch({
          limit: connectedUsers.length,
          users: connectedUsers,
        }).then()
      } else if (connectedUsers.length) {
        handleSearchByDegrees({
          userId: me?.id!,
          degrees,
        }).then()
      }
    },
    [me, handleSearch, handleSearchByDegrees],
  )

  const handleLoadAllUsers = useCallback(
    async (communityIds: string[]) => {
      if (!communityIds?.length) {
        return
      }

      setIsLoading(true)

      const limit = 1000

      // load the first page to get the total pages that need to be loaded
      const result = await loadPeople({
        communityIds,
        limit,
        page: 0,
      })

      handleAppendItems({
        users: result?.nodes || [],
      })

      if (result?.pages) {
        // create the queue with a concurrency limit
        const requestQueue = new PQueue({ concurrency: 5 })

        // generate the requests for each page and add them to the queue
        for (
          let currentPage = 1;
          currentPage <= result?.pages;
          currentPage += 1
        ) {
          requestQueue.add(async () => {
            try {
              const result = await loadPeople({
                communityIds,
                limit,
                page: currentPage,
              })

              handleAppendItems({
                users: result?.nodes || [],
              })
            } catch {
              // TODO: reattempt?
            }
          })
        }

        await requestQueue.onIdle()
      }

      setGraphState(prevState => ({
        ...prevState,
        forceLayoutReset: true,
      }))

      setIsLoading(false)
    },
    [setIsLoading, loadPeople, handleAppendItems, setGraphState],
  )

  const handleLoadAllTags = useCallback(async () => {
    if (!communityId) {
      return
    }

    setIsLoading(true)

    // Our backend forces a limit of 100, throws error otherwise.
    const limit = 100

    const result = await loadTags({
      communityIds: [communityId],
      kinds: {
        [NODE_KIND.custom]: true,
        [NODE_KIND.event]: true,
        [NODE_KIND.group]: true,
        [NODE_KIND.project]: true,
        [NODE_KIND.role]: true,
      },
      limit,
      page: 0,
    })

    handleAppendItems({
      tags:
        (result?.nodes?.map(tag => ({
          id: tag?.tagId,
          name: tag?.tag?.name,
          kind: tag?.tag?.kind,
        })) as ITagNode[]) || [],
    })

    if (result?.pages) {
      // create the queue with a concurrency limit
      const requestQueue = new PQueue({ concurrency: 5 })

      // generate the requests for each page and add them to the queue
      for (
        let currentPage = 1;
        currentPage <= result?.pages;
        currentPage += 1
      ) {
        requestQueue.add(async () => {
          try {
            const result = await loadTags({
              communityIds: [communityId],
              kinds: {
                [NODE_KIND.custom]: true,
                [NODE_KIND.event]: true,
                [NODE_KIND.group]: true,
                [NODE_KIND.project]: true,
                [NODE_KIND.role]: true,
              },
              limit,
              page: currentPage,
            })

            handleAppendItems({
              tags:
                (result?.nodes?.map(tag => ({
                  id: tag?.tagId,
                  name: tag?.tag?.name,
                  kind: tag?.tag?.kind,
                })) as ITagNode[]) || [],
            })
          } catch {
            // TODO: reattempt?
          }
        })
      }

      await requestQueue.onIdle()

      /* Commenting out for now
      setGraphState(prevState => ({
        ...prevState,
        forceLayoutReset: true,
      }))
      */
    }
    setIsLoading(false)
  }, [communityId, handleAppendItems, loadTags, setIsLoading])

  useEventBusSubscribe(EventBus.actions.graph.expandUser, handleExpandUser)
  useEventBusSubscribe(
    EventBus.actions.graph.expandKnowledge,
    handleExpandKnowledge,
  )
  useEventBusSubscribe(EventBus.actions.graph.findPath, handleFindPath)
  useEventBusSubscribe(EventBus.actions.graph.search, handleSearch)
  useEventBusSubscribe(EventBus.actions.search.community, handleCommunitySearch)
  useEventBusSubscribe(EventBus.actions.graph.addUserById, handleAddUserById)
  useEventBusSubscribe(EventBus.actions.graph.addUsersById, handleAddUsersById)
  useEventBusSubscribe(
    EventBus.actions.graph.addOrganizationById,
    handleAddOrganization,
  )

  useEventBusSubscribe(
    EventBus.actions.graph.expandCommunity,
    handleLoadAllUsers,
  )

  return {
    handleSearch,
    handleLoadAllUsers,
    handleLoadAllTags,
    handleMyNetwork,
    handleCommunitySearch,
  }
}
