import React, {
  memo,
  PropsWithChildren,
  useEffect,
  useMemo,
  useState,
} from 'react'

import {
  IGraphCommunityUserSkillNode,
  IGraphCommunityUserTagNode,
  IGraphCommunityUserWorkHistory,
  IGraphPeopleNode,
} from 'Features/GraphNodes/NodeTypes'
import { useGraphLoaders } from 'Features/GraphNodes/useGraphLoader'
import CreateGraphSnapshotModal from 'Features/GraphSnapshot/Modal/CreateGraphSnapshotModal'
import DeleteGraphSnapshotModal from 'Features/GraphSnapshot/Modal/DeleteGraphSnapshotModal'
import ManageGraphSnapshotModal from 'Features/GraphSnapshot/Modal/ManageGraphSnapshotsModal'
import UpdateGraphSnapshotModal from 'Features/GraphSnapshot/Modal/UpdateGraphSnapshotModal'
import { Chart, Index, Items, Link } from 'regraph'

import keys from 'lodash/keys'

import CommunityIntroduce from 'Components/Blocks/Community/CommunityIntroduce'
import AddToCommunityModal from 'Components/Blocks/Modals/AddToCommunity'

import { QuickActionKind } from 'Constants/graph'
import {
  KEYS,
  USER_RELATIONSHIP_STRENGTH,
  UserRelationshipStrength,
} from 'Constants/ids'

import { useAppContext, useCommunityContext, useEntityModal } from 'Hooks'
import {
  GraphContext,
  IGraphContext,
  IGraphState,
  IShowGraphMenu,
  ISubGraph,
  LoadGraphState,
} from 'Hooks/useGraphContext'

import { DEFAULT_LAYOUT, DEFAULT_POSITIONS } from './constants'
import useAction from './useAction'
import useGraphHandlers from './useGraphHandlers'
import useGraphMapper from './useGraphMapper'
import useGraphQuery from './useGraphQuery'
import useKeysDown from './useKeysDown'
import utils, { AnalyzerFunction, COMBO_PROPERTIES, IItemData } from './utils'

import UpdateContactModal from '../Modals/UpdateContactModal/UpdateContactModal'

export interface IOptions {
  users: string[]
  skills: string[]
  needSkills: string[]
  organizations: string[]
  communities: string[]
  tags: string[]
  knowledge: string[]
}

export interface IGraphProvider extends PropsWithChildren {
  graphControls?: {
    reset?: boolean
    options?: boolean
    myNetwork?: boolean
    search?: boolean
    browse?: boolean
  }
  options?: IOptions
  setOptions?: React.Dispatch<React.SetStateAction<IOptions | undefined>>
  quickActions?: QuickActionKind[]
  showTargetConnections?: boolean
  showTargetOrganizations?: boolean
  showTargetSkills?: boolean
  showTargetTags?: boolean
  targetUser?: IGraphPeopleNode
  useQuickActions?: boolean
}

// TODO: How can we break this file up better?
function GraphProvider({
  children,
  targetUser,
  graphControls,
  options,
  setOptions,
  showTargetSkills,
  showTargetTags,
  showTargetConnections,
  showTargetOrganizations,
  quickActions = [],
  useQuickActions = false,
}: IGraphProvider) {
  const { me } = useAppContext()
  const { community } = useCommunityContext()
  const { loadPeople, loadRelationshipEdges } = useGraphLoaders()

  const [addToCommunityModal, addCommunityActions] =
    useEntityModal<MainSchema.GraphUser[]>()
  const [updateContactModal, updateContactActions] =
    useEntityModal<MainSchema.GraphUser>()
  const [createGraphSnapshotModal, createGraphSnapshotActions] =
    useEntityModal()
  const [updateGraphSnapshotModal, updateGraphSnapshotActions] =
    useEntityModal<MainSchema.GraphSnapshot>()
  const [deleteGraphSnapshotModal, deleteGraphSnapshotActions] =
    useEntityModal<MainSchema.GraphSnapshot>()
  const [manageGraphSnapshotsModal, manageGraphSnapshotsActions] =
    useEntityModal()

  const onOpenAddToCommunityModal = addCommunityActions.openModal
  const handleCloseAddToCommunityModal = addCommunityActions.closeModal

  const onUpdateContactModal = updateContactActions.openModal
  const handleUpdateContactModal = updateContactActions.closeModal

  const onCreateGraphSnapshotModal = createGraphSnapshotActions.openModal
  const handleCloseCreateGraphSnapshotModal =
    createGraphSnapshotActions.closeModal

  const onUpdateGraphSnapshotModal = updateGraphSnapshotActions.openModal
  const handleCloseUpdateGraphSnapshotModal =
    updateGraphSnapshotActions.closeModal

  const onDeleteGraphSnapshotModal = deleteGraphSnapshotActions.openModal
  const handleCloseDeleteGraphSnapshotModal =
    deleteGraphSnapshotActions.closeModal

  const onManageGraphSnapshotsModal = manageGraphSnapshotsActions.openModal
  const handleCloseManageGraphSnapshotsModal =
    manageGraphSnapshotsActions.closeModal

  const [paths, setPaths] = useState<IGraphPeopleNode[][]>([])
  const [subGraphs, setSubGraphs] = useState<ISubGraph[]>([])
  const [showGraphMenu, setShowGraphMenu] = useState<IShowGraphMenu | null>(
    null,
  )
  const [showContextMenuIds, setShowContextMenuIds] = useState<string[]>([])
  const [nodes, setNodes] = useState<Items<IItemData>>({})

  const [edges, setEdges] = useState<Index<Link<IItemData>>>({})

  const [currentAnalyzer, setCurrentAnalyzer] =
    useState<AnalyzerFunction | null>(null)
  const [showRelationshipStrength, setShowRelationshipStrength] = useState<
    Record<string, boolean>
  >({})

  // TODO: Load initial state from user settings
  const [clusteringEnabled, setClusteringEnabled] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const initialLayout = useMemo<Chart.LayoutOptions>(() => {
    return { ...DEFAULT_LAYOUT, top: targetUser?.id || me?.id || undefined }
  }, [targetUser?.id, me?.id])

  // Regraph examples, documentations and their recommendation suggested merging state, so multiple state changes can be applied at same time.
  const [graphState, setGraphState] = useState<IGraphState>({
    analyzerFunction: currentAnalyzer,
    items: {},
    appendUsers: [],
    selection: {},
    positions: DEFAULT_POSITIONS,
    combine: {
      properties: COMBO_PROPERTIES,
      level: clusteringEnabled ? 3 : 0,
    },
    openCombos: {},
    firstLoad: true,
    layout: initialLayout,
    clusteringEnabled,
    setClusteringEnabled,
    showRelationshipStrength,
    setShowRelationshipStrength,
  })

  const [graphPeopleData, setGraphPeopleData] = useState<IGraphPeopleNode[]>([])

  const [memoizedRelationshipEdges, setMemoizedRelationshipEdges] = useState<
    Record<string, Record<string, UserRelationshipStrength>>
  >({})

  const selectedIds = useMemo(
    () => keys(graphState.selection),
    [graphState.selection],
  )

  const loadGraphState = useMemo<LoadGraphState>(
    () => ({
      clusteringEnabled: graphState.clusteringEnabled,
      showRelationshipStrength: graphState.showRelationshipStrength,
      analyzerFunction: graphState.analyzerFunction,
      nodes,
      edges,
      layout: graphState.layout,
      positions: graphState.positions,
      selection: graphState.selection,
      combine: graphState.combine,
      openCombos: graphState.openCombos,
    }),
    [
      graphState.clusteringEnabled,
      graphState.showRelationshipStrength,
      graphState.analyzerFunction,
      nodes,
      edges,
      graphState.layout,
      graphState.positions,
      graphState.selection,
      graphState.combine,
      graphState.openCombos,
    ],
  )

  const isFilteringByRelationship = useMemo(
    () =>
      showRelationshipStrength[USER_RELATIONSHIP_STRENGTH.WEAK] ||
      showRelationshipStrength[USER_RELATIONSHIP_STRENGTH.MODERATE] ||
      showRelationshipStrength[USER_RELATIONSHIP_STRENGTH.STRONG] ||
      false,
    [showRelationshipStrength],
  )

  const [currentGraphSnapshotId, setCurrentGraphSnapshotId] = useState<string>()
  const [savedLoadGraphState, setSavedLoadGraphState] =
    useState<LoadGraphState>()

  // TODO: Add control+z or command+z for undo
  const keysDown = useKeysDown()

  const isHandMode = useMemo(
    () => !(keysDown.includes(KEYS.SHIFT) || keysDown.includes(KEYS.META)),
    [keysDown],
  )

  const [myUserNodes, myUserEdges] = useMemo(
    () =>
      utils.appendItems({
        me,
        // This is loading ME into the graph on load
        users: [
          {
            // TEMP: Convert graphUser into IGraphPeopleNode format
            // TODO: Don't get my graph node from ME!!!
            ...(me?.graphUser as IGraphPeopleNode),
            communityUserSkills: me?.graphUser?.skills?.map(skill => ({
              skillId: skill.id,
              skill: {
                id: skill.id,
                name: skill.name,
              },
            })) as IGraphCommunityUserSkillNode[],
            communityUserTags: me?.graphUser?.tags?.map(tag => ({
              tagId: tag.id,
              tag: {
                id: tag.id,
                name: tag.name,
                kind: tag.kind,
              },
            })) as IGraphCommunityUserTagNode[],
            communityUserWorkHistory: me?.graphUser?.workHistory?.map(
              organization => ({
                organizationId: organization.organization?.id,
                isCurrent: organization.isCurrent,
                organization: {
                  ...organization?.organization,
                },
              }),
            ) as IGraphCommunityUserWorkHistory[],
            communityUserEducationHistory: me?.graphUser?.educationHistory?.map(
              organization => ({
                organizationId: organization.id,
                organization: {
                  ...organization?.organization,
                },
              }),
            ),
          },
        ],
        showTargetSkills: false,
        showTargetTags: true,
        showTargetOrganizations: true,
        selectedIds: [],
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      me,
      showTargetOrganizations,
      showTargetSkills,
      showTargetTags,
      targetUser?.id,
    ],
  )

  const [targetUserNodes, targetUserEdges] = useMemo(
    () =>
      targetUser
        ? utils.appendItems({
            me,
            users: [targetUser],
            showTargetSkills,
            showTargetTags,
            showTargetOrganizations,
            selectedIds: [],
          })
        : [{}, {}],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [me, showTargetOrganizations, showTargetSkills, showTargetTags, targetUser],
  )

  const {
    introduceTo,
    availableQuickActions,
    handleIntroduceToCancel,
    handleIntroduceTo,
  } = useAction({
    selectedIds,
    quickActions,
    graphState,
    onOpenAddToCommunityModal,
    onUpdateContactModal,
    onCreateGraphSnapshotModal,
    onUpdateGraphSnapshotModal,
    onDeleteGraphSnapshotModal,
    onManageGraphSnapshotsModal,
  })

  // Core context items
  // Mapping handlers for generating data for regraph
  const graphMapper = useGraphMapper({
    initialLayout,
    nodes,
    edges,
    currentAnalyzer,
    showRelationshipStrength,
    targetUser,
    memoizedRelationshipEdges,
    paths,
    subGraphs,
    graphState,
    isFilteringByRelationship,
    targetUserNodes,
    targetUserEdges,
    myUserNodes,
    myUserEdges,
    savedLoadGraphState,
    onSetNodes: setNodes,
    onSetEdges: setEdges,
    onSetCurrentAnalyzer: setCurrentAnalyzer,
    onSetShowRelationshipStrength: setShowRelationshipStrength,
    onSetSubGraphs: setSubGraphs,
    onSetGraphState: setGraphState,
    onSetShowGraphMenu: setShowGraphMenu,
    onSetContextMenuIds: setShowContextMenuIds,
    setClusteringEnabled,
    setShowRelationshipStrength,
  })

  // Regraph only handlers
  const graphHandler = useGraphHandlers({
    targetUser,
    initialLayout,
    nodes,
    edges,
    graphState,
    onSetPaths: setPaths,
    onSetGraphState: setGraphState,
    onSetShowGraphMenu: setShowGraphMenu,
    onSetContextMenuIds: setShowContextMenuIds,
  })

  const graphQuery = useGraphQuery({
    options,
    setOptions,
    setPaths,
    setIsLoading,
    setGraphState,
    handleAppendItems: graphMapper.handleAppendItems,
    handleTemporaryConnectUser: graphMapper.handleTemporaryConnectUser,
  })

  // Enable/Disable clustering when setting changes, we need to wipe positions and triggers relayout
  useEffect(() => {
    setGraphState(prevState => ({
      ...prevState,
      clusteringEnabled,
      combine: {
        ...prevState.combine,
        properties: COMBO_PROPERTIES,
        level: clusteringEnabled ? 3 : 0,
      },
      positions: DEFAULT_POSITIONS,
      layout: initialLayout,
    }))
    // including initialLayout causes too many relayouts
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clusteringEnabled, setGraphState])

  // Initialize basic graph on initial load
  useEffect(() => {
    if (showTargetConnections && targetUser) {
      const targetUserConnections = keys(targetUser?.connections || {})
      if (targetUserConnections.length)
        graphQuery
          .handleSearch({
            limit: targetUserConnections.length || 1,
            users: targetUserConnections,
          })
          .then()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [targetUser])

  useEffect(() => {
    setGraphState(prevState => ({
      ...prevState,
      appendUsers: graphPeopleData || [],
    }))
  }, [graphPeopleData])

  const memoizedContext = useMemo<IGraphContext>(
    () => ({
      isLoading,
      setIsLoading,
      isHandMode,
      useQuickActions,
      graphState,
      setGraphState,
      showGraphMenu,
      showContextMenuIds,
      setShowContextMenuIds,
      subGraphs,
      graphControls,
      graphMapper,
      graphHandler,
      graphQuery,
      quickActions,
      availableQuickActions,
      currentGraphSnapshotId,
      setCurrentGraphSnapshotId,
      loadGraphState,
      setSavedLoadGraphState,
      options,
    }),
    [
      isLoading,
      setIsLoading,
      isHandMode,
      useQuickActions,
      graphState,
      showGraphMenu,
      showContextMenuIds,
      setShowContextMenuIds,
      subGraphs,
      graphControls,
      graphMapper,
      graphHandler,
      graphQuery,
      quickActions,
      availableQuickActions,
      currentGraphSnapshotId,
      setCurrentGraphSnapshotId,
      loadGraphState,
      setSavedLoadGraphState,
      options,
    ],
  )

  useEffect(() => {
    // Background load the top 25 people in the community
    const fetchGraphPeopleData = async () => {
      if (community?.id) {
        const result = await loadPeople({
          communityIds: [community?.id],
          limit: 25,
          useLegacyBackgroundLoader: true,
        })
        setGraphPeopleData(result?.nodes || [])
      }
    }

    // Background load relationship edges
    const fetchGraphRelationshipEdges = async () => {
      if (community?.id) {
        const result = await loadRelationshipEdges({
          communityIds: [community?.id],
        })
        setMemoizedRelationshipEdges(result?.edges || {})
      }
    }

    fetchGraphPeopleData()
    fetchGraphRelationshipEdges()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [community?.id])

  return (
    <GraphContext.Provider value={memoizedContext}>
      {children}

      {introduceTo.isOpen && (
        <CommunityIntroduce
          userTo={introduceTo.userTo}
          userWhom={introduceTo.userWhom}
          onCancel={handleIntroduceToCancel}
          onSearchSelect={handleIntroduceTo}
        />
      )}

      <AddToCommunityModal
        isOpen={addToCommunityModal.isOpen}
        users={addToCommunityModal?.entity}
        onClose={handleCloseAddToCommunityModal}
      />

      <UpdateContactModal
        isOpen={updateContactModal.isOpen}
        user={updateContactModal?.entity}
        onClose={handleUpdateContactModal}
      />

      <CreateGraphSnapshotModal
        isOpen={createGraphSnapshotModal.isOpen}
        onClose={handleCloseCreateGraphSnapshotModal}
      />

      {updateGraphSnapshotModal.entity && (
        <UpdateGraphSnapshotModal
          graphSnapshot={updateGraphSnapshotModal.entity}
          isOpen={updateGraphSnapshotModal.isOpen}
          onClose={handleCloseUpdateGraphSnapshotModal}
        />
      )}

      {deleteGraphSnapshotModal.entity && (
        <DeleteGraphSnapshotModal
          graphSnapshot={deleteGraphSnapshotModal.entity}
          isOpen={deleteGraphSnapshotModal.isOpen}
          onClose={handleCloseDeleteGraphSnapshotModal}
        />
      )}

      <ManageGraphSnapshotModal
        isOpen={manageGraphSnapshotsModal.isOpen}
        onClose={handleCloseManageGraphSnapshotsModal}
      />
    </GraphContext.Provider>
  )
}

export default memo(GraphProvider)
